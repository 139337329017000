import styled from "styled-components";

const HomeStyled = styled.div`
  background: linear-gradient(168.1deg, #f7eed6 -1.02%, #e3d2f1 100%);
  min-height: 100vh;
  width: 100vw;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 90px;
`;

export { HomeStyled, Main };
