import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fonts, fontSizes, fontWeights, lineHeights } from "../../../../const/fonts";

const DealPersonalTabStyled = styled.div`
  padding: 20px 20px 32px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  background: ${Colors.White};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TabTitle = styled.h1`
  font-size: ${fontSizes.h1};
  font-weight: ${fontWeights.bold};
  line-height: ${lineHeights.h1};
  font-family: ${fonts.secondary};
  color: ${Colors.Dark};
`;

const TabDescription = styled.p`
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  color: ${Colors.DarkGrey};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;
export { ButtonsWrapper, ContentWrapper, DealPersonalTabStyled, TabDescription, TabTitle };
