import { CreateContractButtonStyled, Line, LinesWrapper } from "./CreateContractButton.styled";

type CreateContractButtonProps = {
  onClick?: () => void;
};

export default function CreateContractButton({ onClick }: CreateContractButtonProps) {
  return (
    <CreateContractButtonStyled onClick={onClick}>
      <LinesWrapper>
        <Line />
        <Line />
      </LinesWrapper>
    </CreateContractButtonStyled>
  );
}
