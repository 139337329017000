import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import { routes } from "../../types/enum";

const AuthError = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center", marginTop: "50px", padding: "20px" }}>
      <h1>Authentication Error</h1>
      <p>There was an error with your authentication. Please try again.</p>
      <ButtonComponent
        onClick={() => navigate(routes.SIGN_IN)}
        style={{ marginTop: "20px" }}
      >
        Go to Sign In
      </ButtonComponent>
    </div>
  );
};

export default AuthError;
