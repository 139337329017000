import { ChangeEvent, useState } from "react";
import { InputFieldContainer, StyledInput, StyledLabel } from "./InputComponent.styled";

interface InputFieldProps {
  label: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

export default function InputComponent ({ label, placeholder, onChange }: InputFieldProps) {
  const [value, setValue] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <InputFieldContainer>
      <StyledInput
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={value ? 'filled' : ''}
      />
      <StyledLabel className={value ? 'filled' : ''}>{label}</StyledLabel>
    </InputFieldContainer>
  );
};