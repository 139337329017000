import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fonts, fontSizes, lineHeights } from "../../../../const/fonts";

const UploadTabStyled = styled.div`
  flex: 1;
  height: 100%;
  padding: 20px 20px 32px 20px;
  background: ${Colors.White};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const UploadTitle = styled.h1`
  font-size: ${fontSizes.h1};
  font-weight: 600;
  line-height: ${lineHeights.h1};
  font-family: ${fonts.secondary};
  color: ${Colors.Dark};
  margin-bottom: 40px;
`;

const UploadDescription = styled.p`
  line-height: ${lineHeights.l};
  margin-top: 24px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export { ButtonsWrapper, ContentWrapper, UploadDescription, UploadTabStyled, UploadTitle };
