import styled from "styled-components";
import { Colors } from "../../const/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const Segment = styled.div<{active?: boolean}>`
  flex: 1;
  height: 2px;
  background-color: ${props => (props.active ? Colors.EGreen : Colors.DarkBlue)};
  transition: background-color 0.3s;
`;

export {
  Container,
  Segment
};
