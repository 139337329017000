const values = {
  inprogress: "inprogress",
  paid: "paid",
  finished: "finished",
  accepted: "accepted",
  rejected: "rejected",
};

const result = Object.assign(values, {
  terms: "terms",
  formed: [values.finished, values.accepted, values.rejected],
  draft: [undefined, values.inprogress, values.paid],
});

export default result;
