import styled from "styled-components";
import { Colors } from "../../const/colors";
import { fontSizes, lineHeights } from "../../const/fonts";

const ToastContainer = styled.div`
  position: fixed;
  background-color: ${Colors.White};
  border-radius: 8px;
  box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.16);
  padding: 8px 12px;
  width: calc(100vw - 40px);
  z-index: 1000;
  height: 56px;
  border-bottom: 4px solid ${Colors.EGreen};
  display: flex;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  height: 100%;
  color: ${Colors.DarkBlue};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  font-weight: 600;
`;

const Text = styled.span`
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  color: ${Colors.Dark};
`;

const CheckIcon = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${Colors.EGreen};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const Content = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  flex: 1;
`;

const Divider = styled.div`
  height: 100%;
  width: 1px;
  background: ${Colors.LightGrey};
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 16px;
`;

export { ActionContainer, CheckIcon, CloseButton, Content, Divider, Text, ToastContainer };
