import axios, { AxiosInstance } from "axios";
import { ContractType } from "../types";

class ServiceApi {
  api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      timeout: 15000,
      baseURL: "https://dev.e-gree.app/api",
    });
  }

  public checkAuthorization = () => {
    return !!this.api.defaults.headers.common.Authorization;
  };

  public authorization = (token: string) => {
    if (token) {
      this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete this.api.defaults.headers.common.Authorization;
    }
  };

  authTelegram = async (token: string) =>
    (
      await this.api.get("/auth/telegram", {
        headers: {
          Authorization: token,
        },
      })
    ).data;

  fetchDeals = async (page?: number): Promise<ContractType[]> =>
    (await this.api.get(`/v2/ndas?page=${page}`)).data.data;

  customTemplate = async () =>
    (
      await this.api.get("/templates/custom/en", {
        headers: {
          "x-app-version": 999,
        },
      })
    ).data.template;

  opinionAITemplate = async () =>
    (
      await this.api.get("/templates/opiniongpt/en", {
        headers: {
          "x-app-version": 999,
        },
      })
    ).data.template;

  uploadDocument = async (data: any) =>
    (
      await this.api.post("/attachments", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    ).data;

  saveDeal = async (uuid: string, data: any) =>
    (
      await this.api.post(`/ndas/${uuid}`, data, {
        headers: {
          "x-app-version": 999,
          "Content-Type": "application/json;charset=utf-8",
        },
      })
    ).data.nda;

  fetchDeal = async (uuid: string) =>
    (
      await this.api.get(`/ndas/${uuid}`, {
        headers: {
          "x-app-version": 999,
          "Content-Type": "application/json;charset=utf-8",
        },
      })
    ).data.nda;

  dealInvitation = async (uuid: string) =>
    (
      await this.api.post(`/ndas/${uuid}/invitation`, {
        headers: {
          "x-app-version": 999,
          "Content-Type": "application/json;charset=utf-8",
        },
      })
    ).data;

  acceptDeal = async (uuid: string, data: any) =>
    (
      await this.api.post(`/ndas/${uuid}/accept`, data, {
        headers: {
          "x-app-version": 999,
          "Content-Type": "application/json;charset=utf-8",
        },
      })
    ).data.nda;

  declineDeal = async (uuid: string) =>
    (
      await this.api.post(`/ndas/${uuid}/decline`, {
        headers: {
          "x-app-version": 999,
          "Content-Type": "application/json;charset=utf-8",
        },
      })
    ).data.nda;

  editUser = async (data: any) =>
    (
      await this.api.post(`/user/edit`, data, {
        headers: {
          "x-app-version": 999,
          "Content-Type": "application/json;charset=utf-8",
        },
      })
    ).data;

  getUser = async () =>
    (
      await this.api.get(`/user`, {
        headers: {
          "x-app-version": 999,
          "Content-Type": "application/json;charset=utf-8",
        },
      })
    ).data.user;
}

const serviceApi = new ServiceApi();

export default serviceApi;
