import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";

const NotFound = () => {
  return (
    <div
      style={{
        height: "70vh",
        textAlign: "center",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <h1>404 - Page Not Found</h1>
      <p style={{ marginBottom: "20px" }}>The page you are looking for does not exist.</p>
      <ButtonComponent>Go Home</ButtonComponent>
    </div>
  );
};

export default NotFound;
