import { BackArrow, BackButtonStyled } from "./BackButton.styled";

type BackButtonProps = {
  onClick?: () => void;
  hide?: boolean;
}

export default function BackButton({ onClick, hide }: BackButtonProps){
  return(
    <BackButtonStyled onClick={onClick} $hide={hide}>
      <BackArrow src="/assets/arrow.png"/>
    </BackButtonStyled>
  )
}