import { useState } from "react";
import BackButton from "../../../../components/BackButton/BackButton";
import ButtonComponent, {
  ButtonBackgroundType,
  ButtonColorType,
} from "../../../../components/ButtonComponent/ButtonComponent";
import UploadComponent from "../../../../components/UploadComponent/UploadComponent";
import serviceApi from "../../../../services/api";
import { ContractType, UserType } from "../../../../types";
import { ContractSteps } from "../../ContractCreation";
import { ButtonsWrapper, ContentWrapper, UploadTabStyled, UploadTitle } from "./UploadTab.styled";

type UploadTabProps = {
  setActiveTab: (tab: ContractSteps) => void;
  file: File | null;
  setFile: (file: File | null) => void;
  uuid: string;
  initDeal: () => Promise<void>;
  setDeal: (deal: any) => void;
  user?: UserType;
  deal: ContractType | null;
  type: string | null;
};

const getFileExtensionFromFile = (file: File): string => {
  const fileName = file.name;
  const lastDotIndex = fileName.lastIndexOf(".");
  return lastDotIndex !== -1 ? fileName.substring(lastDotIndex) : ""; // Возвращает расширение с точкой
};

export default function UploadTab({
  setActiveTab,
  file,
  setFile,
  uuid,
  initDeal,
  setDeal,
  user,
  deal,
  type,
}: UploadTabProps) {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    if (file) {
      setLoading(true);
      await initDeal();

      const values = new FormData();
      values.append("parent", uuid);
      values.append("file", file);

      const response = await serviceApi.uploadDocument(values);
      const deal = await serviceApi.fetchDeal(uuid);
      deal.content.fields.agreement = {
        ...deal.content.fields.agreement,
        value: file.name,
        extension: getFileExtensionFromFile(file),
        hash: response.attachment.split(".").slice(0, -1).join("."),
        name: `${response.attachment}`,
      };
      const savedDeal = await serviceApi.saveDeal(uuid, deal);
      setDeal(savedDeal);

      if (type === "solomon") {
        savedDeal.status = "finished";
        const secondPartDeal = await serviceApi.saveDeal(uuid, savedDeal);
        setDeal(secondPartDeal);
      }
      setLoading(false);
    }

    if (user?.name && user.secondName) {
      setActiveTab("preview");
    } else {
      setActiveTab("personal");
    }
  };
  return (
    <UploadTabStyled>
      <ContentWrapper>
        <UploadTitle>Upload agreement</UploadTitle>
        <UploadComponent
          file={file}
          setFile={setFile}
          initFileName={deal?.content.fields.agreement.value}
        />
      </ContentWrapper>

      <ButtonsWrapper>
        <BackButton
          onClick={() => setActiveTab("begin")}
          hide={!!deal?.content.fields.agreement.value}
        />
        <ButtonComponent
          backgroundType={ButtonBackgroundType.DarkBlue}
          colorType={ButtonColorType.White}
          disabled={!(!!file || !!deal?.content.fields.agreement.value) || loading}
          loading={loading}
          onClick={() => handleClick()}
        >
          Next
        </ButtonComponent>
      </ButtonsWrapper>
    </UploadTabStyled>
  );
}
