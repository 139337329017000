import styled from "styled-components";
import { Colors } from "../../../../const/colors";

const CreateContractButtonStyled = styled.div`
  background: ${Colors.DarkBlue};
  height: 56px;
  width: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 500;
  bottom: 100px;
  right: 20px;
  cursor: pointer;
`;

const LinesWrapper = styled.div`
  position: relative;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  background: ${Colors.White};
  width: 24px;
  height: 4px;
  border-radius: 2px;
  position: absolute;

  &:first-child {
    transform: rotate(90deg);
  }
`;

export { CreateContractButtonStyled, Line, LinesWrapper };
