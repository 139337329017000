export enum Colors {
  EGreen = "#05C48D",
  White = "#FFFFFF",
  Beige = "#F3F0E8",
  Dark = "#222222",
  DarkBlue = "#0B3854",
  DarkGrey = "#999999",
  LightGrey = "#E3E3E3",
  Orange = "#F8A506",
  Red = "#FA4E4E",
}