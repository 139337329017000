import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fontSizes, lineHeights } from "../../../../const/fonts";

const BaseStatus = styled.div`
  padding: 4px 8px 6px 8px;
  border-radius: 13px;

  p {
    font-size: ${fontSizes.s};
    line-height: ${lineHeights.s};
  }
`;

const SignedStatus = styled(BaseStatus)`
  border: 1px solid ${Colors.EGreen};
  color: ${Colors.EGreen};
`;

const PendingStatus = styled(BaseStatus)`
  color: ${Colors.White};
  background: ${Colors.Orange};
`;

const RejectedStatus = styled(BaseStatus)`
  border: 1px solid ${Colors.Red};
  color: ${Colors.White};
  background: ${Colors.Red};
`;

const DeletedStatus = RejectedStatus;

const Text = styled.p`
  font-size: ${fontSizes.s};
  line-height: ${lineHeights.s};
`;

export { BaseStatus, DeletedStatus, PendingStatus, RejectedStatus, SignedStatus, Text };
