import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fonts } from "../../../../const/fonts";

const ContractItemStyled = styled.div`
  flex: 1 1 calc(50% - 8px);
  max-width: calc(50% - 8px);
  height: 227px;
  background: ${Colors.White};
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`;

const Title = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.215rem;
  font-family: ${fonts.secondary};
  color: ${Colors.Dark};
`;

const DateText = styled.p`
  color: ${Colors.DarkGrey};
`;

const ItemHeader = styled.div``;

const ItemFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.EGreen};
  color: ${Colors.White};
`;

const SignatureRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Signature = styled.div`
  height: 20px;
`;

const SignatureImage = styled.img`
  max-width: 81px;
  height: 17px;
`;

const RowElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Group = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export {
  Avatar,
  ContractItemStyled,
  DateText,
  Group,
  ItemFooter,
  ItemHeader,
  RowElement,
  Signature,
  SignatureImage,
  SignatureRow,
  Title,
};
