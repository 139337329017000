import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fonts, fontSizes, fontWeights, lineHeights } from "../../../../const/fonts";

const ShareTabStyled = styled.div`
  flex: 1;
  height: 100%;
  padding: 20px 20px 32px 20px;
  background: ${Colors.White};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  padding-top: 15px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;

  @media (max-width: 650px) {
    gap: 8px;
  }
`;

const ShareTabTitle = styled.h1`
  font-size: ${fontSizes.h1};
  font-weight: ${fontWeights.bold};
  line-height: ${lineHeights.h1};
  font-family: ${fonts.secondary};
  color: ${Colors.Dark};

  @media (max-width: 650px) {
    line-height: ${lineHeights.h2};
  }
`;

const ShareTabDescription = styled.p`
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  color: ${Colors.DarkGrey};

  @media (max-width: 650px) {
    font-size: ${fontSizes.m};
    line-height: ${lineHeights.m};
  }
`;

const ShareBlock = styled.a`
  height: 124px;
  border-radius: 4px;
  width: 100%;
  padding: 12px;
  background: ${Colors.Beige};
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  @media (max-width: 650px) {
    height: 100px;
  }
`;

const ShareTitle = styled.h2`
  font-family: ${fonts.secondary};
  font-size: ${fontSizes.h2};
  line-height: ${lineHeights.h2};
  color: ${Colors.Dark};
  font-weight: 600;
`;

const ShareDescription = styled.p`
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  color: ${Colors.DarkGrey};
`;

export {
  ButtonsWrapper,
  ContentWrapper,
  ShareBlock,
  ShareDescription,
  ShareTabDescription,
  ShareTabStyled,
  ShareTabTitle,
  ShareTitle,
};
