import { memo } from "react";
import {
  DeletedStatus,
  PendingStatus,
  RejectedStatus,
  SignedStatus,
  Text,
} from "./StatusComponent.styled";

type Status = "signed" | "rejected" | "pending" | "deleted" | "checked";

type StatusProps = {
  type: Status;
};

const StatusComponent = memo(({ type }: StatusProps) => {
  function renderStatus(type: Status) {
    switch (type) {
      case "signed":
        return (
          <SignedStatus>
            <Text>Signed</Text>
          </SignedStatus>
        );

      case "rejected":
        return (
          <RejectedStatus>
            <Text>Rejected</Text>
          </RejectedStatus>
        );

      case "pending":
        return (
          <PendingStatus>
            <Text>Pending</Text>
          </PendingStatus>
        );

      case "deleted":
        return (
          <DeletedStatus>
            <Text>Deleted</Text>
          </DeletedStatus>
        );

      case "checked":
        return null;

      default:
        return null;
    }
  }
  return <>{renderStatus(type)}</>;
});

export default StatusComponent;
