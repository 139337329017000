import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";
import Loader from "../../components/Loader/Loader";
import { Colors } from "../../const/colors";
import { useTelegram } from "../../hooks/useTelegram";
import serviceApi from "../../services/api";
import { ContractType, UserType } from "../../types";
import { routes } from "../../types/enum";
import DealPersonalTab from "./components/DealPersonalTab/DealPersonalTab";
import DealPreviewTab from "./components/DealPreviewTab/DealPreviewTab";
import DealSignTab from "./components/DealSignTab/DealSignTab";
import { DealStyled } from "./Deal.styled";

export type DeepLinkSteps = "preview" | "personal" | "sign";

export default function DealById() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState<DeepLinkSteps>("preview");
  const signaturePadRef = useRef<SignaturePad>(null);
  const {
    data: deal,
    error: dealError,
    isLoading,
  } = useQuery<ContractType>({
    queryKey: ["deal", id],
    queryFn: () => serviceApi.fetchDeal(id as string),
    staleTime: 0,
    gcTime: 0,
  });
  const { data: user } = useQuery<UserType>({
    queryKey: ["user"],
    queryFn: () => serviceApi.getUser(),
  });
  const navigate = useNavigate();
  const { tg } = useTelegram();

  useEffect(() => {
    tg.BackButton.onClick(() => {
      navigate(routes.HOME);
    });
    tg.BackButton.show();
  });

  useEffect(() => {
    if (
      deal &&
      deal?.content.type !== "opiniongpt" &&
      deal?.signatures.length === 0
    ) {
      navigate(`${routes.CONTRACT_CREATION}?uuid=${deal?.uuid}&step=sign`, {
        replace: true,
      });
    }
  }, [navigate, deal]);

  if (dealError) {
    navigate(routes.NOT_FOUND);
  }

  if (isLoading)
    return <Loader style={{ height: "100vh", background: Colors.White }} />;

  function activeTabRender() {
    switch (activeTab) {
      case "preview":
        return (
          <DealPreviewTab
            setActiveTab={setActiveTab}
            deal={deal}
            profile={user}
          />
        );
      case "personal":
        return <DealPersonalTab setActiveTab={setActiveTab} data={deal} />;
      case "sign":
        if (deal?.uuid) {
          return (
            <DealSignTab
              setActiveTab={setActiveTab}
              signaturePadRef={signaturePadRef}
              uuid={deal?.uuid}
              deal={deal}
            />
          );
        } else return null;
      default:
        return <DealPreviewTab setActiveTab={setActiveTab} deal={deal} />;
    }
  }

  return <DealStyled>{activeTabRender()}</DealStyled>;
}
