import { OwnerBlock, PendingBlock, RejectedBlock, SignedBlock } from "./DealStatus.styled";

export enum DealStatus {
  SIGNED = "signed",
  REJECTED = "rejected",
  PENDING = "pending",
  OWNER = "owner",
  AICHECKED = "aichecked",
}

type StatusComponentProps = {
  status: string;
};

export default function DealStatusComponent({ status }: StatusComponentProps) {
  switch (status) {
    case DealStatus.PENDING: {
      return <PendingBlock>{DealStatus.PENDING}</PendingBlock>;
    }
    case DealStatus.OWNER: {
      return <OwnerBlock>{DealStatus.OWNER}</OwnerBlock>;
    }
    case DealStatus.SIGNED: {
      return <SignedBlock>{DealStatus.SIGNED}</SignedBlock>;
    }
    case DealStatus.REJECTED: {
      return <RejectedBlock>{DealStatus.REJECTED}</RejectedBlock>;
    }
    case DealStatus.AICHECKED: return null;
    default: {
      return <PendingBlock>{DealStatus.PENDING}</PendingBlock>;
    }
  }
}
