import styled from "styled-components";
import { Colors } from "../../const/colors";
import { fontSizes, lineHeights } from "../../const/fonts";
import { ButtonBackgroundType, ButtonColorType } from "./ButtonComponent";

const ButtonComponentStyled = styled.button<{
  $backgroundType: ButtonBackgroundType;
  $colorType: ButtonColorType;
}>`
  font-weight: 700;
  font-size: ${fontSizes.xxl_medium};
  line-height: ${lineHeights.xxl_medium};
  width: 100%;
  height: 56px;
  background-color: ${({ $backgroundType, disabled }) =>
    disabled ? Colors.LightGrey : $backgroundType};
  border: none;
  border-radius: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $colorType, disabled }) => (disabled ? Colors.DarkGrey : $colorType)};
`;

const Spinner = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-left: 5px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { ButtonComponentStyled, Spinner };
