import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";
import BackButton from "../../../../components/BackButton/BackButton";
import ButtonComponent, {
  ButtonBackgroundType,
  ButtonColorType,
} from "../../../../components/ButtonComponent/ButtonComponent";
import { Colors } from "../../../../const/colors";
import { useTelegram } from "../../../../hooks/useTelegram";
import serviceApi from "../../../../services/api";
import { routes } from "../../../../types/enum";
import { DeepLinkSteps } from "../../../deal/DealById";
import {
  ButtonsWrapper,
  ContentWrapper,
  RenewText,
  RenewWrapper,
  SignatureWrapper,
  SignTabStyled,
  SignTitle,
} from "./DealSignTab.styled";

type DealSignTabProps = {
  setActiveTab: (tab: DeepLinkSteps) => void;
  signaturePadRef: React.RefObject<SignaturePad>;
  uuid: string;
  deal: any;
};

export default function DealSignTab({
  setActiveTab,
  signaturePadRef,
  uuid,
  deal,
}: DealSignTabProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { tg } = useTelegram();

  const handleClick = async () => {
    setLoading(true);

    try {
      if (signaturePadRef.current?.isEmpty()) {
        tg.showAlert("Sign the document!");
      } else {
        const values = {
          sign: signaturePadRef.current?.toDataURL(),
        };
        await serviceApi.acceptDeal(uuid, JSON.stringify(values));
        navigate(routes.HOME);
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    tg.disableVerticalSwipes();
    
    const handleTouchMove = (e: TouchEvent) => e.preventDefault();

    window.addEventListener("touchmove", handleTouchMove, { passive: false });
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("touchmove", handleTouchMove);
    };
  });
  return (
    <SignTabStyled>
      <ContentWrapper>
        <SignTitle>Sign here with your finger or stylus</SignTitle>
        <RenewWrapper onClick={() => signaturePadRef.current?.clear()}>
          <img src="/assets/reload.png" width={16} height={12.8} alt="reload" />
          <RenewText>Renew signature</RenewText>
        </RenewWrapper>
        <SignatureWrapper>
          <SignaturePad redrawOnResize ref={signaturePadRef} options={{ penColor: Colors.Dark }} />
        </SignatureWrapper>
      </ContentWrapper>

      <ButtonsWrapper>
        <BackButton onClick={() => setActiveTab("preview")} />
        <ButtonComponent
          backgroundType={ButtonBackgroundType.DarkBlue}
          colorType={ButtonColorType.White}
          onClick={() => handleClick()}
          disabled={signaturePadRef.current?.isEmpty() || loading}
          loading={loading}
        >
          Finish
        </ButtonComponent>
      </ButtonsWrapper>
    </SignTabStyled>
  );
}
