/* eslint-disable max-len */
import React, { memo } from 'react';

type BadgesProps = {
  name: string;
  style?: React.CSSProperties;
}

const Badges = memo(({ name, style }: BadgesProps) => {
  switch (name) {
    default: return (
      <svg viewBox='0 0 32 32' preserveAspectRatio='none' />
    );

    case 'opiniongpt': return (
      <svg  viewBox='0 0 180 180' style={{zIndex: 10, position: 'absolute', width: 40, height: 40, ...style}}>
        <g clipPath='url(#clip0_1_514)'>
          <rect width={180} height={180} rx={90} fill='#F3F0E8' />
          <g clipPath='url(#clip1_1_514)'>
            <path
              d='M34.41 175.321l-9.104 51.499c-5.184 22.875-32.647 20.505-30.23-5.661 0 0 6.305-51.244 7.204-52.111.898-.867 32.13 6.273 32.13 6.273z'
              fill='#FFA88D'
            />
            <path
              d='M30.617 126.247s-25.411 1.702-33.354 63.815c16.323 4.818 28.917 4.564 40.08 3.633 7.617-15.822 17.66-61.548-6.726-67.448z'
              fill='#FA4E4E'
            />
            <path
              d='M26.968 171.719l2.043 13c-.676-.008-1.36-.04-2.043-.064v-12.936z'
              fill='#1B91CC'
            />
            <path
              d='M67.502 123.639l1.829-37.425 35.747 1.71.104 35.715c-8.166 3.705-21.253 3.26-37.68 0z'
              fill='#FFA88D'
            />
            <path
              d='M36.962 230.127s-19.201-95.516-9.986-103.665c9.215-8.15 39.071-10.861 39.071-10.861 18.367 10.05 31.224 16.291 39.604.58 0 0 23.988-2.862 36.487 6.528l-3.347 107.418c14.335 66.35 18.406 186.506 11.171 260.06-3.618 1.741-38.801 1.193-52.954 3.165-14.152 1.98-34.181 2.465-49.813-.096-3.419-.556-6.702-.628-9.835-.214-19.32 2.512-34.46-1.471-37.338-2.863 5.932-87.421 31.597-219.558 36.94-260.052z'
              fill='#04C48B'
            />
            <path
              d='M139.919 198.481l-.031-4.079c-13.446-18.907-55.45-66.231-71.225-78.166l-5.71-.707 76.966 82.952zM25.592 139.899c.008-.024 6.552-2.194 6.56-2.218.548 7.116-4.755 19.527 5.542 48.35 5.883 16.49 10.67 45.471 18.915 66.16 19.162 50.218 86.006 98.441 96.779 105.978l.056 6.059S76.025 309.907 55.138 255.18c-8.237-20.688-13.461-54.559-19.353-71.05-11.489-32.185-12.22-39.23-10.193-44.231z'
              fill='#0FA276'
            />
            <path
              d='M68.663 116.237c26.437 23.885 73.022 83.414 73.022 83.414s13.946 18.979 5.335 30.46c3.641 13.151 4.977 25.785 3.705 37.831 4.222 19.861 11.171 49.654 4.826 60.96 3.634 18.231 1.018 28.695-1.375 29.84 0 0-76.687-51.809-97.575-106.543-8.237-20.689-13.023-49.67-18.915-66.16-7.522-21.07-12.634-35.74-15.266-43.349-1.789-5.176-1.59-10.877.787-15.815 1.026-2.13 2.346-3.832 3.968-4.039 4.73-.612 6.91-4.818 9.899-6.655 6.09-3.729 10.765-1.248 12.936-3.935 2.68-3.69 14.797-.796 18.653 3.991z'
              fill='#04C48B'
            />
            <path
              d='M147.02 230.102c-10.734-33.195-52.445-62.295-78.19-104.459 30.754 44.032 68.466 70.087 80.011 100.73l-1.821 3.729zM150.725 267.933c-10.734-33.195-67.846-108.371-67.846-108.371s56.762 69.769 68.307 100.404c.039 2.425-.151 5.121-.461 7.967zM29.576 122.16C45 140.511 55.082 161.549 62.954 183.915c-10.082-24.29-20.697-46.354-35.978-61.024 1.28-.254 1.836-.373 2.6-.731zM47.783 113.502c5.678 2.687 11.346 9.398 17.023 16.649-4.762-7.394-9.588-14.272-14.788-17.905-.549.62-1.28 1.041-2.235 1.256z'
              fill='#0FA276'
            />
            <path
              d='M111.702 76.887s2.218-1.694 4.532.096c3.037 2.75-2.576 16.076-7.164 14.112-4.603-1.963 2.632-14.208 2.632-14.208z'
              fill='#EF8265'
            />
            <path
              d='M67.995 76.386s-4.5-3.777-6.933-.048.97 11.688 7.323 12.857c0 0 3.045 18.112 22.525 17.42 21.333-.89 20.824-31.939 20.824-31.939.191-6.504-.326-13.031-1.535-19.583l-8.356-3.665-23.138-1.018-5.621 3.872-8.563 11.8 3.474 10.304z'
              fill='#FFA88D'
            />
            <path
              d='M64.362 74.716s-4.826-14.041 3.467-19.694c7.354-6.456.572-8.611 6.273-12.094 5.7-3.482 6.766-2.433 9.486-4 4.675-1.414 9.803-1.931 14.454-.15 2.926 1.12 5.169 3.268 7.379 5.407 1.105 1.065-2.457 3.02-1.161 3.864 1.36.89 6.599 4.563 6.599 4.563s4.079 2.41 2.903 7.164c-.97 5.995.636 9.255-.867 16.546a3.59 3.59 0 00-1.217.613s-1.097-16.713-3.959-19.266c-1.495-1.335-5.51 2.743-13.175 2.83-7.005.08-17.476-4.889-19.32-2.87-3.873 4.23-3.277 21.747-3.277 21.747-.604 1.836-2.608 1.693-2.608 1.693s.207-2.68-1.343-4.667c-1.201-.906-2.41-1.495-3.634-1.686z'
              fill='#0A3855'
            />
            <path
              d='M110.955 83.526s11.735 48.604-14.233 48.604c-21.364.748-27.383-20.784-27.383-51.077.771-1.153 2.52-7.68 2.576-5.216.31 6.584 5.439 13.247 9.033 14.24 3.594.994 1.71-6.201 14.43-7.632 12.73-1.432 10.814 6.607 13.199 6.003 2.378-.605 2.378-4.922 2.378-4.922zm-9.295 5.669c.151-.111.302-.23.405-.398.509-.874-.842-1.145-1.391-1.12-.724.023-1.407.238-2.115.35a9.687 9.687 0 01-3.284-.072c-1.24-.239-2.743-.43-3.697.588-.199.215-.326.5-.31.787.072.93 1.463 1.2 2.179 1.36 2.472.564 5.367.214 7.569-1.066.215-.135.437-.27.644-.43z'
              fill='#0A3855'
            />
            <path
              d='M110.852 52.604l-7.665-38.967c-2.505-5.343-27.789-4.517-33.299 1.327l-2.067 40.058c12.897-4.43 35.732-4.898 43.031-2.418z'
              fill='#F8A605'
            />
            <path
              d='M92.063 33.228c-4.795 9.31-12.96 15.48-24.084 18.891-2.91 0-1.98-17.102.85-16.419 8.429.096 16.022-2.727 23.242-6.845v4.373h-.008z'
              fill='#EF8200'
            />
            <path
              d='M92.063 33.228c4.484 8.865 9.827 13.906 18.199 16.387 2.092-.239-1.327-17.119-3.283-16.212-3.666.81-10.607-1.48-14.916-4.548v4.373z'
              fill='#EF8200'
            />
            <path
              d='M89.548 32.146c-.05-2.138 1.032-3.897 2.415-3.929 1.382-.032 2.543 1.676 2.592 3.814.05 2.137-1.032 3.896-2.414 3.928-1.383.032-2.544-1.676-2.593-3.813z'
              fill='#fff'
            />
            <path
              d='M90.446 32.125c-.031-1.365.662-2.488 1.549-2.509.886-.02 1.63 1.07 1.662 2.435.032 1.365-.662 2.489-1.549 2.51-.886.02-1.63-1.07-1.662-2.436z'
              fill='#F8A605'
            />
            <path
              d='M134.323 175.837l13.867 50.425c7.299 22.295 34.419 17.373 29.561-8.459 0 0-11.059-50.434-12.037-51.213-.97-.787-31.391 9.247-31.391 9.247z'
              fill='#FFA88D'
            />
            <path
              d='M128.122 117.183s34.125 1.041 47.952 72.338c-12.547 3.856-24.696 5.104-36.861 3.769-25.021-40.574-36.368-71.392-11.091-76.107z'
              fill='#FA4E4E'
            />
            <path
              d='M140.016 193.377l.716-23.766 4.15 24.203-4.866-.437z'
              fill='#1B91CC'
            />
            <path
              d='M94.09 174.064H153.532c-6.703-.294-15.21 30.19-12.698 57.955.628 6.925 2.028 12.69 3.841 17.341h31.319c-9.51.048-8.087 5.971-12.921 12.761-2.401 3.372-7.275 2.815-11.887-1.987a24.516 24.516 0 01-1.264 1.987c-3.625 5.097-12.92 1.225-18.398-12.761h-1.821c-9.509.048-8.086 5.971-12.92 12.761-2.402 3.372-7.276 2.815-11.887-1.987a24.793 24.793 0 01-1.264 1.987c-4.835 6.791-19.727-2.329-22.24-30.102-2.512-27.773 5.996-58.257 12.699-57.955z'
              fill='#F4EFE9'
            />
            <path
              d='M107.241 174.056h44.319c8.253.891 9.994 9.939 9.994 9.939h-60.729s-.032-9.637-6.735-9.939h13.151z'
              fill='#fff'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_1_514'>
            <rect width={180} height={180} rx={90} fill='#fff' />
          </clipPath>
          <clipPath id='clip1_1_514'>
            <path
              fill='#fff'
              transform='matrix(-1 0 0 1 250 10)'
              d='M0 0H261V267H0z'
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
});

export default Badges;