import styled, { css } from "styled-components";
import { Colors } from "../../../../../const/colors";

interface AvatarWrapperProps {
  filled: boolean;
}

export const CounterpartyStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;
  cursor: pointer;
`;

export const Main = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  align-self: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const AvatarContainer = styled.div`
  margin-right: 8px;
`;

export const AvatarWrapper = styled.div<AvatarWrapperProps>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.DarkGrey};
  border-width: 1px;
  border-color: ${Colors.White};
  border-radius: 16px;
  overflow: hidden;

  ${props => !props.filled && css`
    background-color: ${Colors.EGreen};
  `}
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const Name = styled.span`
  font-size: 10px;
  font-family: '$bold';
  text-transform: uppercase;
  text-align: center;
  color: ${Colors.White};
`;

export const Title = styled.p`
  font-size: 16px;
  text-align: left;
  color: ${Colors.Dark};
  flex: 1;
`;