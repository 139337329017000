import React from "react";
import { Navigate } from "react-router-dom";
import serviceApi from "../../services/api";

type Props = {
  children: JSX.Element | React.ReactElement | React.ReactNode;
};

const isAuthenticated = () => {
  return serviceApi.checkAuthorization();
};

const ProtectedRoute = ({ children }: Props) => {
  if (!isAuthenticated()) {
    return <Navigate to="/sign-in" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
