import styled from "styled-components";
import { Colors } from "../../const/colors";
import { fontSizes, lineHeights } from "../../const/fonts";

const InputFieldContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 38%;
  left: 0;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  pointer-events: none;
  color: ${Colors.DarkGrey};
  font-size: ${fontSizes.xxl_medium};
  line-height: ${lineHeights.xxl_medium};

  &.filled {
    top: -10px;
    font-size: ${fontSizes.l};
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding-bottom: 8px;
  font-size: ${fontSizes.xxl_medium};
  line-height: ${lineHeights.xxl_medium};
  border: none;
  border-bottom: 1px solid ${Colors.LightGrey};
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${Colors.DarkGrey};
  }

  &:focus + ${StyledLabel},
  &:not(:placeholder-shown) + ${StyledLabel},
  &.filled + ${StyledLabel} {
    top: -10px;
    font-size: ${fontSizes.l};
    color: ${Colors.DarkGrey};
  }
`;

export {
  StyledInput,
  StyledLabel,
  InputFieldContainer
}