import styled from "styled-components";
import { Colors } from "../../const/colors";
import { fontSizes, lineHeights } from "../../const/fonts";

const UploadLabel = styled.label`
  font-size: ${fontSizes.xxl_medium};
  line-height: ${lineHeights.xxl_medium};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: ${Colors.EGreen};
  border: none;
  border-radius: 32px;
  cursor: pointer;
  color: ${Colors.White};
`;

const Input = styled.input`
  display: none;
`;

const UploadDescription = styled.p`
  line-height: ${lineHeights.l};
  margin-top: 24px;
`;

const UploadContentBefore = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const ImageDocument = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadComponentAfter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const DocumentBlock = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  height: 40px;
`;

const DocumentName = styled.p`
  font-size: ${fontSizes.xl};
  line-height: ${lineHeights.xl};
  color: ${Colors.Dark};
`;

const ReloadFile = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;

const ReloadFileImage = styled.div`
  width: 16px;
  height: 16px;
`;

const ReloadText = styled.p`
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  color: ${Colors.DarkBlue};
`;

export {
  DocumentBlock,
  DocumentName,
  ImageDocument,
  Input,
  ReloadFile,
  ReloadFileImage,
  ReloadText,
  UploadComponentAfter,
  UploadContentBefore,
  UploadDescription,
  UploadLabel,
};
