import styled from "styled-components";
import { Colors } from "../../../../../const/colors";
import { fontSizes, lineHeights } from "../../../../../const/fonts";

const InputContainer = styled.div`
  padding: 8px 0px 4px;
  height: 52px;
`;

const Container = styled.div`
  display: flex;
  gap: 36px;
`;

const Label = styled.label`
  color: ${Colors.DarkGrey};
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  width: 80px;
`;

const Input = styled.input`
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  color: ${Colors.Dark};
  border: none;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${Colors.DarkGrey};
  }
`;

const ErrorMessage = styled.p`
  color: ${Colors.Red};
  width: 100%;
  text-align: right;
  font-size: ${fontSizes.s};
  line-height: ${lineHeights.s};
`;

export { Container, ErrorMessage, Input, InputContainer, Label };
