import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fonts } from "../../../../const/fonts";


const BeginTabStyled = styled.div`
  width: 100%;
  padding: 20px 20px 32px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${Colors.White};
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 52px;
  flex: 1;
  justify-content: center;

  @media (max-height: 650px) {
    gap: 30px;
  }
`;

const Description = styled.p`
  color: ${Colors.DarkGrey};
  font-weight: 500;
`;

const Img = styled.img`
  width: 128px;
  height: 128px;
`;

const ImageWrapper = styled.div`
  width: 128px;
  margin: 0 auto;
  height: 128px;
`;

const SolomonWrapper = styled.div`
  width: 180px;
  margin: 0 auto;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  background: ${Colors.Beige};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  font-family: ${fonts.secondary};
  font-weight: 600;
  font-size: 2.25rem;
  color: ${Colors.Dark};
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;



export {
    BeginTabStyled,
    ButtonsWrapper,
    ContentWrapper,
    Description, ImageWrapper, Img,
    Title, TextContent, SolomonWrapper
};
