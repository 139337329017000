import styled from "styled-components";
import { Colors } from "../../const/colors";

const BackButtonStyled = styled.button<{$hide?: boolean}>`
  background: ${Colors.Beige};
  min-width: 56px;
  height: 56px;
  display: ${props => props.$hide ? "none" : "flex"};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
`;

const BackArrow = styled.img`
  width: 22px;
  height: 11.5px;
`;

export {
  BackButtonStyled,
  BackArrow,
}