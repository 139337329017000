import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import BackButton from "../../../../components/BackButton/BackButton";
import ButtonComponent, {
  ButtonBackgroundType,
  ButtonColorType,
} from "../../../../components/ButtonComponent/ButtonComponent";
import { ContractSteps } from "../../ContractCreation";

import { useNavigate } from "react-router-dom";
import { routes } from "../../../../types/enum";
import {
  ButtonsWrapper,
  ContentHeader,
  ContentWrapper,
  PdfContent,
  PreviewDescription,
  PreviewTabStyled,
  PreviewTitle,
} from "./PreviewTab.styled";

type PreviewTabProps = {
  setActiveTab: (tab: ContractSteps) => void;
  uuid: string;
  type?: string | null;
};

export default function PreviewTab({
  setActiveTab,
  uuid,
  type = "custom",
}: PreviewTabProps) {
  const pdfLink = `https://dev.e-gree.app/api/v2/nda/download/${uuid}?preview=formation`;
  const navigate = useNavigate();

  const handleNext = () => {
    switch (type) {
      case "solomon":
        navigate(routes.HOME);
        break;
      case "custom":
      default:
        setActiveTab("sign");
        break;
    }
  };
  return (
    <PreviewTabStyled>
      <ContentWrapper>
        <ContentHeader>
          <PreviewTitle>
            {type === "solomon" ? "Uploaded agreement" : "e-gree formation"}
          </PreviewTitle>
          {type !== "solomon" && (
            <PreviewDescription>
              Your e-greement will include the following clauses. After
              reviewing you can add any additional term you wish to include.
              Once you are satisfied with the terms of the e-greement, you can
              proceed to the next section.
            </PreviewDescription>
          )}
        </ContentHeader>

        <PdfContent>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={pdfLink}
              defaultScale={SpecialZoomLevel.PageWidth}
            />
          </Worker>
        </PdfContent>
      </ContentWrapper>

      <ButtonsWrapper>
        <BackButton onClick={() => setActiveTab("upload")} />
        <ButtonComponent
          backgroundType={ButtonBackgroundType.DarkBlue}
          colorType={ButtonColorType.White}
          onClick={() => handleNext()}
        >
          {type === "solomon" ? "Finish" : "Next"}
        </ButtonComponent>
      </ButtonsWrapper>
    </PreviewTabStyled>
  );
}
