import { useNavigate } from "react-router-dom";
import Icon from "../../../../components/Icon/Icon";
import { ContactType, ContractType, UserType } from "../../../../types";
import { routes, StatusNames } from "../../../../types/enum";
import { formatDate } from "../../../../utils";
import statuses from "../../../../utils/statuses";
import StatusComponent from "../StatusComponent/StatusComponent";
import {
  ContractItemStyled,
  DateText,
  Group,
  ItemFooter,
  ItemHeader,
  RowElement,
  SignatureImage,
  Title,
} from "./ContractItem.styled";
import Images from "./Images/Images";

type ContractItemProps = {
  deal: ContractType;
  profile: UserType;
};

interface Creator {
  status: number;
  family: string | null;
  name: string | null;
  owner: boolean;
  self: boolean;
  userId: number;
  creator?: any;
}

type Counterparty = ContactType & {
  self: boolean;
  family: string | null;
  name: string | null;
  userId: number;
};

type Person = Creator | Counterparty;

interface BaseItem {
  userId: number;
  family: string | null;
  name: string | null;
  self: boolean;
}

export interface SignedItem extends BaseItem {
  sign?: {
    width: number;
    height: number;
    data: string;
  } | null;
}

interface CheckedItem {
  name: string;
  family: string;
  opiniongpt: boolean;
  avatar: () => JSX.Element;
}

interface Groups {
  signed: SignedItem[];
  pending: BaseItem[];
  rejected: BaseItem[];
  deleted: BaseItem[];
  checked: CheckedItem[];
}

export default function ContractItem({ deal, profile }: ContractItemProps) {
  const navigate = useNavigate();

  const owner = deal.owner === profile?.id;
  const creator: Creator = {
    status: statuses.formed.indexOf(deal.status) >= 0 ? 1 : 0,
    family: deal.content.owner?.secondName,
    name: deal.content.owner?.name,
    owner: owner,
    self: owner,
    userId: deal.owner,
    creator: deal.content.owner,
  };

  const counterparties: Counterparty[] = deal.contacts
    ?.sort((item) => {
      return profile?.id === item?.id ? -1 : 0;
    })
    ?.map((item) =>
      Object.assign({}, item, {
        self: profile?.id === item?.id,
        family: item.secondName,
        name: item.name,
        userId: item?.id,
      })
    );

  const all: Person[] = [creator, ...counterparties];

  const users: Groups = all.reduce(
    (data, item) => {
      if (item) {
        const signature =
          deal.signatures && deal.signatures.find((signature) => signature.userId === item.userId);

        if(deal.content.type === "opiniongpt") {
          data.checked.push({
            // if its AI contract show 'checked' status
            name: "Solomon",
            family: "AI",
            opiniongpt: true,
            avatar: () => <Icon style={{ width: "40px", height: "40px" }} name="solomon" />,
          });
        } else {
          switch (Number(item.status)) {
            case 1: {
              data.signed.push({ ...item, sign: signature?.thumbnailSign });
              break;
            }
            case 2:
              data.rejected.push(item);
              break;
            default:
              data.pending.push(item);
              break;
          }
        }
        
      }

      return data;
    },
    {
      signed: [],
      pending: [],
      rejected: [],
      deleted: [],
      checked: [],
    } as Groups
  );

  let signed = null;

  if (
    !users?.pending?.length &&
    !users?.rejected?.length &&
    !users?.deleted?.length &&
    users?.signed?.length === 2
  ) {
    signed = (
      <>
        <RowElement>
          <Images items={users.signed.slice(1)} />
          {users.signed.slice(1)[0].sign ? (
            <SignatureImage src={users.signed.slice(1)[0].sign?.data} />
          ) : (
            <StatusComponent type={StatusNames.SIGNED} />
          )}
        </RowElement>

        <RowElement>
          <Images items={users.signed.slice(0, 1)} />
          {users.signed.slice(0, 1)[0].sign ? (
            <SignatureImage src={users.signed.slice(0, 1)[0].sign?.data} />
          ) : (
            <StatusComponent type={StatusNames.SIGNED} />
          )}
        </RowElement>
      </>
    );
  } else if (users?.signed?.length) {
    const owner = users?.signed.find((user) => user.self);
    signed = (
      <RowElement>
        <Images items={users.signed} />
        {owner?.sign ? (
          <SignatureImage
            src={owner.sign.data}
            width={owner.sign.width / 2}
            height={owner.sign.height / 2}
          />
        ) : (
          <StatusComponent type={StatusNames.SIGNED} />
        )}
      </RowElement>
    );
  }

  return (
    <ContractItemStyled onClick={() => navigate(`${routes.DEALS}/${deal.uuid}`)}>
      <ItemHeader>
        <Title>{deal.content.title}</Title>
        <DateText>{formatDate(deal.created_at)}</DateText>
      </ItemHeader>
      <ItemFooter>
        {!users?.checked?.length ? null : (
          <Group>
            <Images items={users.checked} />

            <StatusComponent type={StatusNames.CHECKED} />
          </Group>
        )}

        {!users?.pending?.length ? null : (
          <Group>
            <Images items={users.pending} />

            <StatusComponent type={StatusNames.PENDING} />
          </Group>
        )}

        {!users?.rejected?.length ? null : (
          <Group>
            <Images items={users.rejected} />

            <StatusComponent type={StatusNames.REJECTED} />
          </Group>
        )}

        {!users?.deleted?.length ? null : (
          <Group>
            <Images items={users.deleted} />

            <StatusComponent type={StatusNames.DELETED} />
          </Group>
        )}

        {signed}
      </ItemFooter>
    </ContractItemStyled>
  );
}
