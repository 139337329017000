import { ChangeEvent } from "react";
import {
  Container,
  ErrorMessage,
  Input,
  InputContainer,
  Label,
} from "./InputProfileComponent.styled";

interface InputFieldProps {
  label: string;
  placeholder?: string;
  onChange: (value: string) => void;
  required?: boolean;
  value: string;
}

export default function InputProfileComponent({
  label,
  placeholder,
  onChange,
  required,
  value,
}: InputFieldProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <InputContainer>
      <Container>
        <Label>{label}</Label>
        <Input placeholder={placeholder} type="text" value={value} onChange={handleChange} />
      </Container>

      <ErrorMessage>{required && "Required"}</ErrorMessage>
    </InputContainer>
  );
}
