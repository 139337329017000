import { useEffect } from "react";
import {
  ActionContainer,
  CheckIcon,
  CloseButton,
  Content,
  Divider,
  Text,
  ToastContainer,
} from "./Toats.styled";

interface ToastProps {
  message: string;
  duration?: number;
  onClose: () => void;
  style?: React.CSSProperties;
  visible: boolean;
}

export default function Toast({ message, duration = 5000, onClose, style, visible }: ToastProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!visible) return null;

  return (
    <ToastContainer style={style}>
      <Content>
        <CheckIcon />
        <Text>{message}</Text>
      </Content>

      <ActionContainer>
        <Divider />

        <CloseButton onClick={() => onClose()}>Close</CloseButton>
      </ActionContainer>
    </ToastContainer>
  );
}
