export enum routes {
  HOME = "/",
  SIGN_IN = "/sign-in",
  DEALS = "/deals",
  CONTRACT_CREATION = "/contract-creation",
  SETTINGS = "/settings",
  PROFILE = "/settings/profile",
  AUTH_ERROR = "/auth-error",
  NOT_FOUND = "/not-found",
}

export enum StatusNames {
  PENDING = "pending",
  EXPIRED = "expired",
  DRAFT = "draft",
  SIGNED = "signed",
  REJECTED = "rejected",
  CHECKED = "checked",
  DELETED = "deleted",
}