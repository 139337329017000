import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fonts, fontSizes, lineHeights } from "../../../../const/fonts";

const SignTabStyled = styled.div`
  flex: 1;
  height: 100%;
  padding: 20px 20px 32px 20px;
  background: ${Colors.White};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  padding-top: 15px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`;

const SignTitle = styled.h1`
  font-size: ${fontSizes.h1};
  font-weight: 600;
  line-height: ${lineHeights.h1};
  font-family: ${fonts.secondary};
  color: ${Colors.Dark};
`;

const RenewWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const RenewText = styled.p`
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  color: ${Colors.DarkBlue};
`;

const SignatureWrapper = styled.div`
  width: 100%;
  height: 40vh;
  position: relative;

  canvas {
    width: 100% !important;
    height: 100% !important;
    display: block;
    box-sizing: border-box;
  }
`;

export {
  ButtonsWrapper,
  ContentWrapper,
  RenewText,
  RenewWrapper,
  SignatureWrapper,
  SignTabStyled,
  SignTitle,
};
