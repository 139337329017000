import styled from "styled-components";
import { Colors } from "../../../const/colors";
import { fonts, fontSizes, lineHeights } from "../../../const/fonts";

const ProfileDetailsStyled = styled.div`
  background: linear-gradient(168.1deg, #f7eed6 -1.02%, #e3d2f1 100%);
  height: 100vh;
  width: 100vw;
  padding: 32px 20px 16px 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Avatar = styled.div`
  background: ${Colors.White};
  height: 90px;
  width: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserForm = styled.div`
  display: flex;
  padding: 12px 16px 4px 12px;
  flex-direction: column;
  background: ${Colors.White};
  border-radius: 4px;
`;

const Divider = styled.div`
  height: 1px;
  background: ${Colors.LightGrey};
  width: 100%;
  margin-bottom: 10px;
`;

const Name = styled.h1`
  color: ${Colors.Dark};
  font-family: ${fonts.secondary};
  font-size: ${fontSizes.h1};
  line-height: ${lineHeights.h1};
  font-weight: 600;
`;

const Id = styled.p`
  color: ${Colors.Dark};
  font-family: ${fonts.secondary};
  font-size: ${fontSizes.m};
  line-height: ${lineHeights.m};
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

const Content = styled.div`
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100% - 90px);
`;

export {
  Avatar,
  ButtonWrapper,
  Content,
  Divider,
  Header,
  Name,
  ProfileDetailsStyled,
  UserData,
  UserForm,
  Id
};
