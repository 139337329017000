import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../types/enum";

export function Deals() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(routes.HOME);
  });

  return null;
}
