import { ContractType, UserType } from "../../../../types";
import ContractItem from "../ContractItem/ContractItem";
import {
  ContractsListStyled,
  ContractsWrapper,
  NoContractsText,
  NoContractsWrapper,
} from "./ContractsList.styled";

type ContractsListProps = {
  contracts?: ContractType[];
  profile: UserType;
};

export default function ContractsList({ contracts, profile }: ContractsListProps) {
  return (
    <ContractsListStyled>
      {(!contracts || contracts.length === 0) && (
        <NoContractsWrapper>
          <NoContractsText>No contracts here</NoContractsText>
        </NoContractsWrapper>
      )}
      {contracts && contracts.length > 0 && (
        <ContractsWrapper>
          {contracts.map((el) => (
            <ContractItem deal={el} key={el.uuid} profile={profile} />
          ))}
        </ContractsWrapper>
      )}
    </ContractsListStyled>
  );
}
