import React, { memo } from "react";
import styled from "styled-components";
import { Colors } from "../../const/colors";
import { fonts, fontSizes, lineHeights } from "../../const/fonts";
import Solomon from "../Solomon/Solomon";

interface CardProps {
  onPress: () => void;
  label?: string;
  button?: string;
}

const PromoCard: React.FC<CardProps> = memo(({ onPress, label = "", button = "" }) => {
  return (
    <Container>
        <BadgeContainer>
          <Badge>
            <BadgeLabel>BETA</BadgeLabel>
          </Badge>
        </BadgeContainer>

        <Content>
          <Solomon style={{ width: "95%", height: 154, bottom: 29, left: 0 }} />
        </Content>
        <Content>
          <Text>{label}</Text>

          <Button onClick={onPress}>
            <ButtonText>{button}</ButtonText>
          </Button>
        </Content>
    </Container>
  );
});

export default PromoCard;

const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: row;
  background-color: ${Colors.White};
  border-radius: 4px;
  padding: 20px 20px 15px;
  width: 100%;
  max-height: 150px;
  position:relative;
`;

const Content = styled.div`
  flex-grow: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const Text = styled.p`
  align-self: flex-start;
  font-family: ${fonts.secondary};
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  font-weight: 600;

  @media (max-width: 375px) {
    font-size: ${fontSizes.m};
    line-height: ${lineHeights.m};
  }
`;

const Button = styled.button`
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  background-color: ${Colors.EGreen};
  border-radius: 32px;
  border: none;
  cursor: pointer;

  @media (max-width: 375px) {
    font-size: ${fontSizes.m};
    line-height: ${lineHeights.m};
    padding: 4px 10px;
  }

  @media (max-width: 340px) {
    font-size: ${fontSizes.s};
    line-height: ${lineHeights.s};
    padding: 2px 6px;
  }
`;

const ButtonText = styled.span`
  color: ${Colors.White};
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
`;

const BadgeContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 50px;
  overflow: hidden;
`;

const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.DarkBlue};
  transform: rotate(45deg) translate(20px, -5px);
  z-index: 1000;
`;

const BadgeLabel = styled.span`
  font-size: 10px;
  font-family: "Regular";
  text-align: center;
  color: white;
`;
