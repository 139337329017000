import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { Colors } from "./const/colors";
import { useTelegram } from "./hooks/useTelegram";
import AuthError from "./pages/auth-error/AuthError";
import ContractCreation from "./pages/contract-creation/ContractCreation";
import DealById from "./pages/deal/DealById";
import { Deals } from "./pages/deals/Deals";
import Home from "./pages/home/Home";
import NotFound from "./pages/not-found/NotFound";
import ProfileDetails from "./pages/settings/ProfileDetails/ProfileDetails";
import Settings from "./pages/settings/Settings";
import SignIn from "./pages/sign-in/SignIn";
import { routes } from "./types/enum";

function App() {
  const { tg } = useTelegram();
  const navigate = useNavigate();

  useEffect(() => {
    if (tg) {
      tg.ready();
      tg.disableVerticalSwipes();
      tg.setHeaderColor(Colors.White);
      tg.setBackgroundColor(Colors.White);
      tg.expand();
    } else {
      navigate(routes.NOT_FOUND);
    }
  });
  return (
    <Routes>
      <Route path={routes.SIGN_IN} element={<SignIn />} />
      <Route path={routes.AUTH_ERROR} element={<AuthError />} />

      <Route
        index
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.CONTRACT_CREATION}
        element={
          <ProtectedRoute>
            <ContractCreation />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${routes.DEALS}/:id`}
        element={
          <ProtectedRoute>
            <DealById />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.DEALS}
        element={
          <ProtectedRoute>
            <Deals />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.SETTINGS}
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.PROFILE}
        element={
          <ProtectedRoute>
            <ProfileDetails />
          </ProtectedRoute>
        }
      />

      <Route path={routes.NOT_FOUND} element={<NotFound />} />

      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
