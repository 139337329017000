export const fonts = {
  primary: 'Lato',
  secondary: "Recoleta Alt",
};

export const fontSizes = {
  h1: '2.25rem',
  h2: '1.5rem',
  h3: '1.125rem',
  xxl_medium: '1.5rem',
  xl: '1.25rem',
  l: '1rem',
  m: '0.875rem',
  s: '0.75rem',
};

export const fontWeights = {
  normal: 500,
  bold: 700
};

export const lineHeights = {
  h1: '2.5rem',
  h2: '1.75rem',
  h3: '1.25rem',
  xxl_medium: '1.75rem',
  xl: '1.75rem',
  l: '1.5rem',
  m: '1.375rem',
  s: '0.875rem',
};