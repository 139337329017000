export function formatDate(dateString: string): string {
  const inputDate = new Date(dateString);

  if (isNaN(inputDate.getTime())) {
    return '';
  }

  const now = new Date();

  const inputYear = inputDate.getFullYear();
  const inputMonth = inputDate.getMonth();
  const inputDay = inputDate.getDate();

  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth();
  const nowDay = now.getDate();

  const isToday = inputYear === nowYear && inputMonth === nowMonth && inputDay === nowDay;
  const isYesterday = inputYear === nowYear && inputMonth === nowMonth && inputDay === nowDay - 1;

  if (isToday) {
    return "Today";
  }

  if (isYesterday) {
    return "Yesterday";
  }

  const options: Intl.DateTimeFormatOptions = { month: "short", day: "numeric", year: "numeric" };
  return inputDate.toLocaleDateString("en-US", options);
}

export function getInitials(obj: any) {
  const { name, secondName } = obj;

  if (name || secondName) {
    if (secondName) {
      return name[0].toUpperCase() + secondName[0].toUpperCase();
    } else {
      return name[0].toUpperCase() + (name[1] ? name[1].toUpperCase() : "");
    }
  }
}
