import { useState } from "react";
import BackButton from "../../../../components/BackButton/BackButton";
import ButtonComponent, {
  ButtonBackgroundType,
  ButtonColorType,
} from "../../../../components/ButtonComponent/ButtonComponent";
import InputComponent from "../../../../components/InputComponent/InputComponent";
import serviceApi from "../../../../services/api";
import { ContractType } from "../../../../types";
import { ContractSteps } from "../../ContractCreation";
import {
  ButtonsWrapper,
  ContentWrapper,
  DealPersonalTabStyled,
  TabDescription,
  TabTitle,
} from "./PersonalTab.styled";

type PersonalTabProps = {
  setActiveTab: (tab: ContractSteps) => void;
  data: ContractType | null;
};

export default function PersonalTab({ setActiveTab, data }: PersonalTabProps) {
  const [userData, setUserData] = useState({
    name: "",
    secondName: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (field: string, value: string) => {
    setUserData({
      ...userData,
      [field]: value,
    });
  };

  async function handleClick() {
    setLoading(true);

    try {
      await serviceApi.editUser(JSON.stringify(userData));
      setActiveTab("preview");
    } catch (error) {}

    setLoading(false);
  }

  return (
    <DealPersonalTabStyled>
      <ContentWrapper>
        <TabTitle>Personal data</TabTitle>
        <TabDescription>
          Please provide accurate information. This is necessary to make your
          e-greement legitimate.
        </TabDescription>

        <InputComponent
          label="Your name"
          placeholder="Your name"
          onChange={(value) => handleInputChange("name", value)}
        />
        <InputComponent
          label="Your last name"
          placeholder="Your last name"
          onChange={(value) => handleInputChange("secondName", value)}
        />
      </ContentWrapper>

      <ButtonsWrapper>
        <BackButton onClick={() => setActiveTab("preview")} />
        <ButtonComponent
          backgroundType={ButtonBackgroundType.DarkBlue}
          colorType={ButtonColorType.White}
          disabled={!(!!userData.name && !!userData.secondName)}
          onClick={handleClick}
          loading={loading}
        >
          Next
        </ButtonComponent>
      </ButtonsWrapper>
    </DealPersonalTabStyled>
  );
}
