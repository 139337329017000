import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fontSizes, lineHeights } from "../../../../const/fonts";

const StatusBlock = styled.div`
  padding: 2px 8px 4px 8px;
  font-size: ${fontSizes.l};
  border-radius: 17px;
  font-weight: 600;
  line-height: ${lineHeights.l};

  &::first-letter {
    text-transform: capitalize;
  }
`;

const PendingBlock = styled(StatusBlock)`
  background: ${Colors.Orange};
  color: ${Colors.White};
`;

const OwnerBlock = styled(StatusBlock)`
  background: ${Colors.White};
  color: ${Colors.DarkBlue};
`;

const SignedBlock = styled(StatusBlock)`
  background: ${Colors.EGreen};
  color: ${Colors.White};
`;

const RejectedBlock = styled(StatusBlock)`
  background: ${Colors.Red};
  color: ${Colors.White};
`;

export { OwnerBlock, PendingBlock, RejectedBlock, SignedBlock };
