import { ChangeEvent, useState } from "react";
import { Colors } from "../../const/colors";
import {
  DocumentBlock,
  DocumentName,
  ImageDocument,
  Input,
  ReloadFile,
  ReloadFileImage,
  ReloadText,
  UploadComponentAfter,
  UploadContentBefore,
  UploadDescription,
  UploadLabel,
} from "./UploadComponent.styled";

export enum ButtonBackgroundType {
  Beige = Colors.Beige,
  White = Colors.White,
  EGreen = Colors.EGreen,
  DarkBlue = Colors.DarkBlue,
}

export enum ButtonColorType {
  Dark = Colors.Dark,
  White = Colors.White,
  DarkBlue = Colors.DarkBlue,
}

type UploadComponentProps = {
  initFileName?: string;
  file: File | null;
  setFile: (file: File | null) => void;
};

export default function UploadComponent({ file, setFile, initFileName }: UploadComponentProps) {
  const [initValue, setInitValue] = useState(initFileName);
  const handleReloadClick = () => {
    setFile(null);
    setInitValue("");
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
  };

  return (
    <>
      {!!(!file && !initValue) ? (
        <UploadContentBefore>
          <UploadLabel htmlFor="upload">
            Upload
            <Input type="file" id="upload" onChange={handleFileChange} accept=".docx, .pdf" />
          </UploadLabel>
          <UploadDescription>Please, upload only .docx, .pdf file</UploadDescription>
        </UploadContentBefore>
      ) : (
        <UploadComponentAfter>
          <DocumentBlock>
            <ImageDocument>
              <img src="./assets/document.png" width={48} height={64} alt="document" />
            </ImageDocument>
            <DocumentName>{initValue ? initValue : file?.name}</DocumentName>
          </DocumentBlock>
          <ReloadFile onClick={handleReloadClick}>
            <ReloadFileImage>
              <img src="./assets/reload.png" alt="reload" width={16} height={12.8} />
            </ReloadFileImage>
            <ReloadText>Reload file</ReloadText>
          </ReloadFile>
        </UploadComponentAfter>
      )}
    </>
  );
}
