import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../../const/colors';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 16px solid inherit;
  border-top: 16px solid ${Colors.EGreen}; 
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: ${spin} 1s linear infinite;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

type LoaderProps = {
  style?: React.CSSProperties;
}

const Loader = ({style}: LoaderProps) => {
    return (
      <LoaderWrapper style={style}>
        <Spinner />
      </LoaderWrapper>
    );
}

export default Loader;