import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../const/colors";
import { routes } from "../../types/enum";
import ContractIcon from "../Icons/ContractIcon";
import SettingsIcon from "../Icons/SettingsIcon";
import { FooterTabsStyled, FooterTabsWrapper, TabText, TabWrapper } from "./FooterTabs.styled";

type Tabs = "contracts" | "settings" | "notifications";

enum TabsNames {
  contracts = "Contracts",
  settings = "Settings & more",
  notifications = "Notifications",
}

type FooterProps = {
  activeTab: Tabs;
};

export default function FooterTabs({ activeTab }: FooterProps) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <FooterTabsStyled>
      <FooterTabsWrapper>
        <TabWrapper
          onClick={() => (location.pathname !== routes.HOME ? navigate(routes.HOME) : null)}
        >
          <ContractIcon color={activeTab === "contracts" ? Colors.EGreen : Colors.Dark} />
          <TabText $active={activeTab === "contracts"}>{TabsNames.contracts}</TabText>
        </TabWrapper>

        <TabWrapper
          onClick={() => (location.pathname !== routes.SETTINGS ? navigate(routes.SETTINGS) : null)}
        >
          <SettingsIcon color={activeTab === "settings" ? Colors.EGreen : Colors.Dark} />
          <TabText $active={activeTab === "settings"}>{TabsNames.settings}</TabText>
        </TabWrapper>
      </FooterTabsWrapper>
    </FooterTabsStyled>
  );
}
