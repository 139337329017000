import styled from "styled-components";
import { Colors } from "../../const/colors";

const FooterTabsStyled = styled.div`
  width: 100%;
  max-width: 100vw;
  background: ${Colors.White};
  position: fixed;
  bottom: 0;
  left: 0;
  height: 80px;
  z-index: 500;
`;

const FooterTabsWrapper = styled.div`
  padding: 12px 63px 0px 63px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
`;

const TabWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  height: 40px;
  cursor: pointer;
`;

const TabText = styled.p<{$active?: boolean}>`
  color: ${(props)=> props.$active ? Colors.EGreen : Colors.Dark};
  font-size: 0.875rem;
  font-weight: 500;
`

export {
  FooterTabsStyled,
  FooterTabsWrapper, TabText, TabWrapper
};
