import { useEffect, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import BackButton from "../../../../components/BackButton/BackButton";
import ButtonComponent, {
  ButtonBackgroundType,
  ButtonColorType,
} from "../../../../components/ButtonComponent/ButtonComponent";
import { Colors } from "../../../../const/colors";
import { useTelegram } from "../../../../hooks/useTelegram";
import serviceApi from "../../../../services/api";
import { ContractSteps } from "../../ContractCreation";
import {
  ButtonsWrapper,
  ContentWrapper,
  RenewText,
  RenewWrapper,
  SignatureWrapper,
  SignTabStyled,
  SignTitle,
} from "./SignTab.styled";

type SignTabProps = {
  setActiveTab: (tab: ContractSteps) => void;
  signaturePadRef: React.RefObject<SignaturePad>;
  uuid: string;
  deal: any;
};

export default function SignTab({ setActiveTab, signaturePadRef, uuid, deal }: SignTabProps) {
  const [loading, setLoading] = useState(false);
  const { tg } = useTelegram();
  useEffect(() => {
    tg.disableVerticalSwipes();

    const handleTouchMove = (e: TouchEvent) => e.preventDefault();
    window.addEventListener("touchmove", handleTouchMove, { passive: false });
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("touchmove", handleTouchMove);
    };
  });

  const handleClick = async () => {
    setLoading(true);

    if (signaturePadRef.current?.isEmpty()) {
      tg.showAlert("Sign the document!");
    } else {
      const values = Object.assign(deal, {
        sign: signaturePadRef?.current?.toDataURL(),
      });
      await serviceApi.saveDeal(uuid, JSON.stringify(values));
      setActiveTab("share");
    }

    setLoading(false);
  };
  return (
    <SignTabStyled>
      <ContentWrapper>
        <SignTitle>Sign here with your finger or stylus</SignTitle>
        <RenewWrapper onClick={() => signaturePadRef?.current?.clear()}>
          <img src="./assets/reload.png" width={16} height={12.8} alt="reload" />
          <RenewText>Renew signature</RenewText>
        </RenewWrapper>
        <SignatureWrapper>
          <SignaturePad redrawOnResize ref={signaturePadRef} options={{ penColor: Colors.Dark }} />
        </SignatureWrapper>
      </ContentWrapper>

      <ButtonsWrapper>
        <BackButton onClick={() => setActiveTab("preview")} />
        <ButtonComponent
          backgroundType={ButtonBackgroundType.DarkBlue}
          colorType={ButtonColorType.White}
          onClick={() => handleClick()}
          disabled={loading}
          loading={loading}
        >
          Next
        </ButtonComponent>
      </ButtonsWrapper>
    </SignTabStyled>
  );
}
