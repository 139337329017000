import styled from "styled-components";
import { fontSizes, lineHeights } from "../../../../../const/fonts";

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  
  button {
    font-size: ${fontSizes.xxl_medium};
    line-height: ${lineHeights.xxl_medium};
    font-weight: 500;
  }
`;

export {
  ButtonsWrapper
}