import CryptoJS from "crypto-js";

function b64EncodeUnicode(str: string) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
}

class AuthService {
  public getAuthToken = (data: any) => {
    const payload = JSON.stringify(data);

    const signature = CryptoJS.HmacSHA256(
      payload,
      process.env.REACT_APP_SHA256_SECRET_KEY as string
    ).toString();

    const token = `${b64EncodeUnicode(payload)}.${signature}`;
    return token;
  };
}

const authService = new AuthService();

export default authService;
