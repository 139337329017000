import React, { Fragment, memo } from "react";
import styled from "styled-components";
import { Colors } from "../../../../../const/colors";
import { fontSizes, lineHeights } from "../../../../../const/fonts";

interface Item {
  image?: string;
  name: string | null;
  family: string | null;
  secondName?: string | null;
  self?: boolean;
  phone?: string;
  opiniongpt?: boolean;
  avatar?: () => JSX.Element;
  sign?: {
    width: number;
    height: number;
    data: string;
  } | null;
}

interface ImagesProps {
  items: Item[];
}

const Container = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-direction: row;
  align-items: center;
  margin-right: 7px;
`;

const Avatar = styled.div<{ isEmpty: boolean; zIndex: number }>`
  width: 38px;
  height: 38px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.isEmpty ? "#05c48d" : "#BDBDBD")};
  border: 1px solid ${Colors.White};
  overflow: hidden;
  position: relative;
  z-index: ${(props) => props.zIndex};
`;

const Name = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  color: ${Colors.White};
`;

const OtherText = styled.span`
  margin-start: 4px;
  font-size: ${fontSizes.s};
  line-height: ${lineHeights.s};
  color: ${Colors.DarkGrey};
`;

const Images: React.FC<ImagesProps> = ({ items }) => {
  if (!Array.isArray(items)) return null;

  const count = items.length > 3 ? 2 : 3;
  const current = items.slice(0, count);
  const other = items.slice(count);
  
  return (
    <Container>
      {current.map(({ name, family, self, opiniongpt, avatar, phone }, index) => {
        const filled =
          (!!name?.length && name?.length > 0) || (!!family?.length && family?.length > 0);

        if (opiniongpt)
          return (
            <Avatar
              key={index}
              isEmpty={false}
              zIndex={count - index}
              style={{ background: "none" }}
            >
              {avatar && avatar()}
            </Avatar>
          );

        return (
          <Fragment key={index}>
            {/* {!phone && (
                <Icon
                  name="attention"
                  style={{
                    width: "11px",
                    height: "11px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 11,
                  }}
                />
              )} */}

            <Avatar
              isEmpty={!filled}
              zIndex={count - index}
              style={{ marginLeft: index > 0 ? "-18px" : undefined }} // Adjust margin if needed
            >
              {self && <Name>You</Name>}

              {!self && (
                <Name>
                  {name}
                  {family}
                </Name>
              )}
            </Avatar>
          </Fragment>
        );
      })}

      {other.length ? <OtherText>{`+ ${other.length}`}</OtherText> : null}
    </Container>
  );
};

export default Images;
