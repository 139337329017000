import React, { memo } from 'react';

interface IconProps {
  style?: React.CSSProperties;
}

const Solomon = memo(({ style }: IconProps) => {
  return (
    <svg
      style={style}
      width={style?.width || 150}
      height={style?.height || 154}
      viewBox='0 0 150 154'
      fill='none'
    >
      <g clipPath='url(#clip0_1_1090)'>
        <path
          d='M25.864 95.191l-5.242 29.653c-2.985 13.171-18.798 11.807-17.406-3.26 0 0 3.63-29.506 4.148-30.005.517-.5 18.5 3.612 18.5 3.612z'
          fill='#FFA88D'
        />
        <path
          d='M23.68 66.935s-14.631.98-19.205 36.744c9.4 2.774 16.65 2.627 23.078 2.092 4.386-9.11 10.169-35.44-3.873-38.836z'
          fill='#FA4E4E'
        />
        <path
          d='M22.23 115.774l-8.59.408 8.955-2.482-.366 2.074z'
          fill='#EF8265'
        />
        <path
          d='M21.58 93.117l1.176 7.485c-.39-.004-.783-.023-1.177-.036v-7.45zM23.163 103.203v-.005l.444 2.829-2.028.055v-2.893c.531.009 1.058.018 1.584.014z'
          fill='#1B91CC'
        />
        <path
          d='M4.883 100.772l.425-2.623c5.608 1.437 11.034 2.234 16.271 2.412v2.623c-5.384-.109-10.965-.956-16.696-2.412z'
          fill='#FECD32'
        />
        <path
          d='M21.58 103.189v-2.623c.393.013.787.032 1.176.036l.407 2.596v.005c-.526.004-1.053-.005-1.584-.014z'
          fill='#F7AB26'
        />
        <path
          d='M44.919 65.433l1.053-21.55 20.583.985.06 20.565c-4.702 2.133-12.238 1.877-21.696 0z'
          fill='#FFA88D'
        />
        <path
          d='M27.334 126.748s-11.056-54.997-5.75-59.69c5.306-4.693 22.497-6.254 22.497-6.254 10.575 5.787 17.978 9.38 22.803.334 0 0 13.813-1.648 21.01 3.76l-1.928 61.85c8.255 38.204 10.599 107.389 6.433 149.741-2.084 1.003-22.342.687-30.49 1.822-8.15 1.14-19.682 1.42-28.683-.055-1.968-.32-3.86-.361-5.663-.123-11.125 1.446-19.842-.847-21.499-1.648 3.415-50.337 18.194-126.421 21.27-149.737z'
          fill='#04C48B'
        />
        <path
          d='M86.616 108.526l-.018-2.348C78.856 95.291 54.67 68.042 45.587 61.17l-3.287-.407 44.316 47.763zM20.787 74.795c.005-.014 3.773-1.263 3.777-1.277.316 4.097-2.738 11.244 3.191 27.839 3.388 9.496 6.144 26.183 10.891 38.095 11.034 28.916 49.522 56.682 55.725 61.022l.032 3.489S49.826 172.685 37.8 141.174c-4.742-11.913-7.75-31.416-11.143-40.911-6.615-18.532-7.036-22.588-5.869-25.468z'
          fill='#0FA276'
        />
        <path
          d='M45.587 61.17C60.81 74.924 87.633 109.2 87.633 109.2s8.03 10.928 3.072 17.539c2.096 7.572 2.865 14.847 2.133 21.782 2.43 11.437 6.432 28.591 2.779 35.101 2.092 10.498.586 16.522-.792 17.182 0 0-44.156-29.831-56.183-61.347-4.743-11.913-7.499-28.6-10.891-38.095a2719.026 2719.026 0 01-8.79-24.96c-1.03-2.98-.916-6.263.453-9.106.59-1.227 1.35-2.206 2.284-2.326 2.724-.352 3.979-2.774 5.7-3.831 3.507-2.148 6.199-.72 7.449-2.267 1.543-2.124 8.52-.457 10.74 2.299z'
          fill='#04C48B'
        />
        <path
          d='M90.704 126.734c-6.18-19.114-30.197-35.87-45.02-60.148 17.707 25.354 39.421 40.357 46.069 58.001l-1.049 2.147zM92.838 148.517c-6.18-19.114-39.065-62.4-39.065-62.4s32.683 40.173 39.33 57.812c.023 1.397-.087 2.949-.265 4.588zM23.08 64.581c8.882 10.566 14.687 22.68 19.22 35.558-5.805-13.986-11.917-26.69-20.716-35.137.737-.146 1.057-.215 1.497-.42zM33.565 59.596c3.269 1.547 6.533 5.411 9.802 9.586-2.743-4.257-5.522-8.217-8.516-10.31-.316.358-.737.6-1.286.724zM96.198 187.001c-20.77-17.722-39.056-38.91-49.04-69.68 10.286 28.746 27.157 50.044 48.482 66.296l.558 3.384zM33.135 125.164h-6.529v4.793h7.6l-1.072-4.793z'
          fill='#0FA276'
        />
        <path d='M22.76 100.597l.403 2.596-.407-2.596h.005z' fill='#F8A605' />
        <path
          d='M70.369 38.513s1.277-.975 2.61.055c1.748 1.584-1.484 9.257-4.126 8.126-2.65-1.13 1.516-8.18 1.516-8.18z'
          fill='#EF8265'
        />
        <path
          d='M45.203 38.225s-2.591-2.175-3.992-.028c-1.401 2.147.558 6.73 4.216 7.403 0 0 1.754 10.429 12.97 10.03 12.283-.512 11.99-18.39 11.99-18.39a53.38 53.38 0 00-.883-11.276l-4.812-2.11-13.322-.586-3.237 2.23-4.93 6.793 2 5.934z'
          fill='#FFA88D'
        />
        <path
          d='M43.11 37.263s-2.778-8.085 1.997-11.34c4.235-3.717.33-4.958 3.612-6.963s3.896-1.401 5.462-2.303c2.692-.815 5.644-1.112 8.323-.087 1.684.646 2.975 1.882 4.248 3.113.636.614-1.414 1.74-.668 2.225.783.513 3.8 2.628 3.8 2.628s2.348 1.387 1.67 4.125c-.558 3.452.367 5.329-.498 9.527a2.063 2.063 0 00-.7.353s-.633-9.624-2.28-11.093c-.861-.77-3.173 1.58-7.587 1.63-4.033.045-10.063-2.816-11.125-1.653-2.23 2.435-1.886 12.521-1.886 12.521-.348 1.058-1.502.975-1.502.975s.12-1.543-.773-2.687c-.692-.522-1.387-.86-2.092-.97z'
          fill='#0A3855'
        />
        <path
          d='M69.939 42.336s6.757 27.986-8.195 27.986c-12.302.43-15.767-11.967-15.767-29.41.444-.664 1.45-4.422 1.483-3.003.178 3.79 3.131 7.627 5.2 8.2 2.07.571.985-3.572 8.31-4.396 7.33-.824 6.226 3.805 7.6 3.457 1.369-.348 1.369-2.834 1.369-2.834zM64.587 45.6a.856.856 0 00.233-.229c.293-.504-.485-.66-.801-.646-.417.014-.81.138-1.218.202a5.58 5.58 0 01-1.89-.041c-.715-.138-1.58-.247-2.13.339a.632.632 0 00-.178.453c.041.535.842.691 1.255.783 1.423.325 3.09.123 4.358-.614.124-.078.252-.156.37-.247z'
          fill='#0A3855'
        />
        <path
          d='M69.88 24.531L65.465 2.094c-1.442-3.077-16-2.6-19.173.765l-1.19 23.064c7.425-2.55 20.573-2.82 24.776-1.392z'
          fill='#F8A605'
        />
        <path
          d='M59.06 13.374c-2.76 5.361-7.462 8.914-13.867 10.878-1.675 0-1.14-9.848.49-9.454 4.853.055 9.225-1.57 13.382-3.942v2.518h-.005z'
          fill='#EF8200'
        />
        <path
          d='M59.06 13.374c2.583 5.105 5.66 8.007 10.48 9.436 1.204-.137-.765-9.857-1.89-9.335-2.111.467-6.108-.851-8.59-2.619v2.518z'
          fill='#EF8200'
        />
        <path
          d='M57.612 12.751c-.028-1.23.595-2.243 1.39-2.262.797-.018 1.465.965 1.494 2.196.028 1.231-.594 2.244-1.39 2.262-.797.018-1.465-.965-1.494-2.196z'
          fill='#fff'
        />
        <path
          d='M58.13 12.74c-.019-.787.38-1.433.891-1.445.51-.012.94.616.958 1.402.018.786-.382 1.433-.892 1.445-.51.011-.94-.616-.957-1.402z'
          fill='#F8A605'
        />
        <path
          d='M83.394 95.488l7.984 29.035c4.203 12.837 19.819 10.003 17.022-4.871 0 0-6.369-29.04-6.932-29.488-.558-.454-18.074 5.324-18.074 5.324z'
          fill='#FFA88D'
        />
        <path
          d='M106.408 110.647l-5.974 3.31 5.594-4.995.38 1.685z'
          fill='#EF8265'
        />
        <path
          d='M79.823 61.715s19.65.6 27.611 41.652c-7.224 2.221-14.22 2.939-21.224 2.17-14.408-23.362-20.94-41.107-6.387-43.822z'
          fill='#FA4E4E'
        />
        <path
          d='M86.672 105.587l.412-13.684 2.39 13.936-2.802-.252z'
          fill='#1B91CC'
        />
        <path
          d='M106.898 100.73l-.586-2.582c-5.608 1.438-12.503 2.248-17.74 2.426l.453 2.647c5.388-.115 12.141-1.035 17.873-2.491z'
          fill='#FECD32'
        />
        <path
          d='M88.572 100.579h-1.75l-.077 2.642h2.28l-.453-2.642z'
          fill='#F7AB26'
        />
        <path
          d='M134.325 109.996c-8.685 7.659-17.338 14.463-28.737 21.339-9.17 5.608-19.631-7.531-7.215-16.179 10.85-6.396 19.48-9.115 30.394-13.968l5.558 8.808z'
          fill='#FFA88D'
        />
        <path
          d='M149.076 109.667s2.023-8.062 1.085-9.71c-.939-1.644-8.795-2.743-8.795-2.743-4.697-.627-12.526 3.928-12.526 3.928s-2.76 10.017.994 10.924c3.378.293 6.143-.055 8.401-.939.128.998.782 1.406 1.707 1.516-.018.595.142.856.756 1.213 3.52.165 6.519-.343 7.279-3.718.494-.045.86-.201 1.099-.471z'
          fill='#FFA88D'
        />
        <path
          d='M138.235 111.132l3.268-1.891a3.739 3.739 0 01-1.368-.902c-.728.252-1.543.266-2.468 0-1.438 1.026-3.598 1.612-6.263 1.9 0 0 5.173-.572 5.521 1.328l1.31-.435zM146.123 105.657c-.325-.921-.403-1.85-.138-2.779a4.115 4.115 0 01-1.959-.293c-.009.503-.169.943-.508 1.309l2.605 1.763z'
          fill='#EF8265'
        />
        <path
          d='M143.518 103.894s-.998-.526-1.195-.627c-.197-.096-3.251-.311-3.915-.568-.663-.256-.293-1.662-.155-1.959.137-.293 4.441-.92 4.441-.92-.074 1.304.338 2.243 1.332 2.76.169.545-.005.98-.508 1.314zM145.985 102.878c.206-1.126.806-2.115 1.763-2.98-1.067.444-1.909 1.323-2.349 2.994.211.077.417.123.586-.014zM147.409 108.532s-.572 1.318-1.969 1.318c-1.396 0-4.042-.659-4.042-.659l-.572.439s3.465.701 4.642.573c1.167-.129 1.625-.705 1.941-1.671z'
          fill='#EF8265'
        />
        <path
          d='M143.485 100.264s2.486-4.19 1.786-7c-.234-.939-2.408-3.695-5.398-6.858l-2.531 1.74 1.584-2.73c-3.329-3.424-7.44-7.146-10.993-9.494l-1.309 1.419.307-2.056c-2.353-1.423-4.381-2.124-5.659-1.575-1.318.994 2.125 7.184 6.108 13.373l2.751-.796-2.175 1.684c3.146 4.826 6.762 9.884 8.296 11.912l1.831-1.043-1.263 1.707c1.433 1.497 4.949.257 6.665-.283z'
          fill='#04C48B'
        />
        <path
          d='M146.123 105.657a89.948 89.948 0 00-2.816-5.288l.362-.206c1.231 2.138 2.499 4.647 3.383 6.469-.037-.041-.266-.467-.929-.975zM147.468 107.557c.98 2.101 1.676 3.731 1.69 3.758l-.385.156a85.534 85.534 0 00-1.332-3.031c.155-.481.027-.883.027-.883zM143.302 100.364l.366-.201c-5.196-9.472-12.484-18.207-21.659-25.954l-.27.316c9.138 7.714 16.39 16.408 21.563 25.839z'
          fill='#0FA176'
        />
        <path
          d='M131.839 112.171s-3.402.096-3.814-2.499c.087 1.565 1.181 2.568 3.131 3.09l.683-.591z'
          fill='#EF8265'
        />
        <path
          d='M51.356 126.913c-11.221 2.861-25.386 6.034-38.662 6.963-10.718.815-14.042-15.653.952-17.69 12.571-.746 24.855-.297 36.78.357l.93 10.37z'
          fill='#FFA88D'
        />
        <path
          d='M60.228 94.467h34.226c-3.86-.17-8.758 17.383-7.311 33.37.362 3.988 1.167 7.307 2.211 9.985h18.033c-5.475.028-4.656 3.438-7.439 7.348-1.383 1.941-4.189 1.621-6.844-1.144-.216.375-.454.755-.728 1.144-2.088 2.935-7.44.705-10.594-7.348h-1.048c-5.476.028-4.656 3.438-7.44 7.348-1.382 1.941-4.189 1.621-6.844-1.144-.215.375-.453.755-.728 1.144-2.784 3.91-11.358-1.341-12.805-17.333-1.447-15.991 3.452-33.544 7.311-33.37z'
          fill='#F4EFE9'
        />
        <path
          d='M63.328 146.406c.975 0 1.808-.426 2.394-1.259 2.783-3.983 1.969-7.453 7.44-7.485h34.225c-5.475.032-4.656 3.502-7.439 7.485-.59.847-1.442 1.273-2.431 1.259H63.328z'
          fill='#EAE0D6'
        />
        <path
          d='M67.8 94.463H93.32c4.752.512 5.754 5.722 5.754 5.722H64.106s-.019-5.548-3.878-5.722H67.8zM81.072 137.662h25.519c4.752.513 5.755 5.722 5.755 5.722H77.382s-.018-5.548-3.877-5.722h7.567z'
          fill='#fff'
        />
        <path
          d='M47.212 114.052c.99-.554 3.915-.041 5.865.284l-.137 3.594c-.247.041-.385.119-.399.252-.04.458 13.968 2.838 13.968 2.838 2.587.334 2.015 3.979-.677 3.274l1.117.325c2.101.622 1.552 3.932-.856 3.438l-.623-.119c-.275 1.126-1.483 1.717-2.848 1.547-.114 1.703-4.065 1.827-11.253.893-3.497-.714-7.627-1.927-9.508-3.933-3.75-4.028-1.447-10.007 5.352-12.393z'
          fill='#FFA88D'
        />
        <path
          d='M65.832 124.298a127.636 127.636 0 01-11.848-1.932c5.42.774 10.836 1.543 13.555 1.694-.462.339-1.03.417-1.707.238zM65.47 127.947a147.51 147.51 0 01-11.651-2.239c4.949.957 9.573 1.717 13.665 2.147-.512.33-1.199.335-2.014.092zM62.623 129.494l-9.198-.288 9.124.6.074-.312zM44.987 128.483s-1.95-.893-2.495-1.451c.545.828 1.035 1.364 1.484 1.693l1.011-.242zM52.935 118.379l-2.701-.811 2.71.142-.009.669z'
          fill='#EF8265'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_1090'>
          <path
            fill='#fff'
            transform='matrix(-1 0 0 1 150 0)'
            d='M0 0H150V154H0z'
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default Solomon;