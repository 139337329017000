import { Colors } from "../../const/colors";
import { ButtonComponentStyled, Spinner } from "./ButtonComponent.styled";

export enum ButtonBackgroundType {
  Beige = Colors.Beige,
  White = Colors.White,
  EGreen = Colors.EGreen,
  DarkBlue = Colors.DarkBlue,
}

export enum ButtonColorType {
  Dark = Colors.Dark,
  White = Colors.White,
  DarkBlue = Colors.DarkBlue,
  Red = Colors.Red,
}

type ButtonComponentProps = {
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  loading?: boolean;
  backgroundType?: ButtonBackgroundType;
  colorType?: ButtonColorType;
  style?: React.CSSProperties;
};

export default function ButtonComponent({
  children,
  onClick,
  style,
  disabled = false,
  loading = false,
  backgroundType = ButtonBackgroundType.EGreen,
  colorType = ButtonColorType.White,
}: ButtonComponentProps) {
  return (
    <ButtonComponentStyled
      style={style}
      $backgroundType={backgroundType}
      $colorType={colorType}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {children}
      {loading && <Spinner />}
    </ButtonComponentStyled>
  );
}
