import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fonts, fontSizes, lineHeights } from "../../../../const/fonts";

const PreviewTabStyled = styled.div`
  flex: 1;
  height: 100%;
  max-height: 100%;
  padding: 20px 20px 32px 20px;
  background: ${Colors.White};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  padding-top: 15px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  flex: 1;

  @media (max-width: 650px) {
    gap: 8px;
  }
`;

const PreviewTitle = styled.h1`
  font-size: ${fontSizes.h1};
  font-weight: 600;
  line-height: ${lineHeights.h1};
  font-family: ${fonts.secondary};
  color: ${Colors.Dark};
`;

const PreviewDescription = styled.p`
  font-size: ${fontSizes.m};
  line-height: ${lineHeights.m};
  color: ${Colors.DarkGrey};
`;

const PdfContent = styled.div`
  width: 100%;
  overflow: auto;
  flex: 1 1 44vh;

  @media (max-width: 650px) {
    flex: 1 1 40vh;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-height: 650px) {
    gap: 4px;
  }
`;

export {
  ButtonsWrapper,
  ContentHeader,
  ContentWrapper,
  PdfContent,
  PreviewDescription,
  PreviewTabStyled,
  PreviewTitle,
};
