import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import { CustomAgreement, HeaderWrapper, StepsLayoutStyled } from "./StepsLayout.styled";

type StepsLayoutProps = {
  totalSegments: number;
  activeSegment: number;
  children: string | JSX.Element;
  type?: string | null;
};

export default function StepsLayout({
  totalSegments,
  activeSegment,
  children,
  type = "custom",
}: StepsLayoutProps) {
  const renderName = () => {
    switch (type) {
      case "custom":
      default:
        return "Custom Agreement";
      case "solomon":
        return "Solomon AI";
    }
  };

  return (
    <StepsLayoutStyled>
      <HeaderWrapper>
        <CustomAgreement>{renderName()}</CustomAgreement>
        <ProgressBar totalSegments={totalSegments} activeSegment={activeSegment} />
      </HeaderWrapper>
      {children}
    </StepsLayoutStyled>
  );
}
