import ButtonComponent, { ButtonBackgroundType, ButtonColorType } from "../../../../../components/ButtonComponent/ButtonComponent";
import { DeepLinkSteps } from "../../../DealById";
import { ButtonsWrapper } from "./ActionsComponent.styled";

type ActionsComponentProps = {
  master: boolean;
  decision: boolean;
  signed: boolean;
  aiopinion: boolean;
  setActiveTab: (val: DeepLinkSteps) => void;
  handleClick: (value: "accept" | "refuse") => Promise<void>;
}

export default function ActionsComponent ({master, decision, signed, aiopinion, setActiveTab, handleClick}: ActionsComponentProps) {
  const renderDecision = () => {
    return (
      <ButtonsWrapper>
          <ButtonComponent
            backgroundType={ButtonBackgroundType.Beige}
            colorType={ButtonColorType.Red}
            onClick={()=>handleClick("refuse")}
          >
            Refuse
          </ButtonComponent>
          <ButtonComponent onClick={()=>handleClick("accept")}>Sign</ButtonComponent>
      </ButtonsWrapper>
    );
  }

  return (
    <>
      {
        (!master && decision) && renderDecision()
      }
    </>
  )
}