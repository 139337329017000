import DealStatusComponent from "../../DealStatus/DealStatus";
import {
  AvatarContainer,
  AvatarWrapper,
  CounterpartyStyled,
  Main,
  Name,
  Title
} from "./Counterparty.styled";


type CounterpartyProps = {
  name: string;
  family: string;
  self: boolean;
  status: string;
  avatar?: () => JSX.Element;
};

export default function Counterparty({
  name,
  family,
  self,
  status,
  avatar,
}: CounterpartyProps) {
  const filled = (!!name?.length && name?.length > 0) || (!!family?.length && family?.length > 0);

  return (
    <CounterpartyStyled>
      <AvatarContainer>
        <AvatarWrapper filled={filled}>
          {!avatar && (
            <Name>
              <span>{name?.replace(/\W/, "").charAt(0)}</span>
              <span>{family?.replace(/\W/, "").charAt(0)}</span>
            </Name>
          )}
          {avatar ? avatar() : null}
        </AvatarWrapper>
      </AvatarContainer>
      <Main>
        <Title>
          {self && "You"}
          {!self && filled && (
            <>
              <span>{name}</span>
              <span>{name ? " " : ""}</span>
              <span>{family}</span>
            </>
          )}
          {!self && !filled && <span>-</span>}
        </Title>
      </Main>

      <DealStatusComponent status={status} />
    </CounterpartyStyled>
  );
}
