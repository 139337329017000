import { Container, Segment } from "./ProgressBar.styled";

type ProgressBarProps = {
  totalSegments: number;
  activeSegment: number;
}

const ProgressBar = ({ totalSegments, activeSegment }: ProgressBarProps) => {
  return (
    <Container>
      {Array.from({ length: totalSegments }, (_, index) => (
        <Segment key={index} active={index < activeSegment} />
      ))}
    </Container>
  );
};

export default ProgressBar;