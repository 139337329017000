import { memo } from "react";

interface IconProps {
  name: string;
  fill?: string;
  style?: React.CSSProperties;
  defs?: React.ReactNode;
}

const Icon: React.FC<IconProps> = memo(
  ({ name, style }) => {
    switch (name) {
      case "solomon":
        return (
          <svg
            width={style?.width || "32"}
            height={style?.height || "32"}
            viewBox="0 0 32 32"
            style={style}
          >
            <g clipPath="url(#clip0_1_77)">
              <rect width={32} height={32} rx={16} fill="#fff" />
              <g clipPath="url(#clip1_1_77)">
                <path
                  d="M5.673 31.39l-1.619 9.156c-.921 4.066-5.804 3.645-5.374-1.007 0 0 1.121-9.11 1.28-9.264.16-.154 5.713 1.115 5.713 1.115z"
                  fill="#FFA88D"
                />
                <path
                  d="M4.999 22.666S.48 22.97-.931 34.011c2.902.857 5.14.811 7.125.646C7.55 31.844 9.334 23.715 5 22.667z"
                  fill="#FA4E4E"
                />
                <path
                  d="M4.35 30.75l.363 2.311c-.12-.001-.242-.007-.364-.011v-2.3z"
                  fill="#1B91CC"
                />
                <path
                  d="M11.556 22.202l.325-6.653 6.355.304.018 6.35c-1.451.658-3.778.579-6.698 0z"
                  fill="#FFA88D"
                />
                <path
                  d="M6.126 41.133s-3.413-16.98-1.775-18.429c1.638-1.449 6.946-1.93 6.946-1.93 3.265 1.786 5.55 2.896 7.04.103 0 0 4.265-.51 6.487 1.16l-.595 19.096c2.549 11.796 3.273 33.157 1.986 46.234-.643.31-6.898.212-9.414.562-2.516.352-6.076.438-8.855-.017a5.98 5.98 0 00-1.749-.038c-3.435.447-6.126-.261-6.638-.509C.614 71.824 5.177 48.333 6.126 41.134z"
                  fill="#04C48B"
                />
                <path
                  d="M24.43 35.508l-.006-.726c-2.39-3.36-9.857-11.774-12.662-13.896l-1.015-.125L24.43 35.508zM4.105 25.093c.002-.004 1.165-.39 1.166-.394.098 1.265-.845 3.471.986 8.595 1.046 2.932 1.897 8.084 3.362 11.762 3.407 8.928 15.29 17.5 17.206 18.84l.01 1.078S13.07 55.317 9.357 45.587c-1.465-3.678-2.393-9.699-3.44-12.63-2.043-5.723-2.173-6.975-1.813-7.864z"
                  fill="#0FA276"
                />
                <path
                  d="M11.762 20.886c4.7 4.247 12.982 14.83 12.982 14.83s2.48 3.373.948 5.415c.648 2.338.885 4.584.659 6.725.75 3.531 1.986 8.828.858 10.837.646 3.242.18 5.102-.245 5.305 0 0-13.633-9.21-17.346-18.94-1.465-3.679-2.316-8.83-3.363-11.762a839.144 839.144 0 01-2.714-7.707 3.7 3.7 0 01.14-2.811c.182-.38.417-.682.705-.718.841-.11 1.229-.857 1.76-1.183 1.083-.663 1.914-.222 2.3-.7.476-.656 2.63-.142 3.316.71z"
                  fill="#04C48B"
                />
                <path
                  d="M25.692 41.13c-1.908-5.902-9.323-11.076-13.9-18.571 5.467 7.828 12.172 12.46 14.224 17.907l-.324.663zM26.35 47.855c-1.907-5.902-12.06-19.266-12.06-19.266s10.09 12.403 12.143 17.85c.007.43-.027.91-.082 1.416zM4.813 21.94c2.743 3.262 4.535 7.002 5.934 10.978-1.792-4.318-3.68-8.24-6.396-10.849.228-.045.327-.066.462-.13zM8.05 20.4c1.01.478 2.017 1.67 3.027 2.96-.847-1.314-1.705-2.537-2.63-3.183a.722.722 0 01-.397.223z"
                  fill="#0FA276"
                />
                <path
                  d="M19.413 13.891s.395-.301.806.017c.54.489-.458 2.858-1.274 2.509-.818-.35.468-2.526.468-2.526z"
                  fill="#EF8265"
                />
                <path
                  d="M11.643 13.802s-.8-.671-1.232-.009c-.433.663.172 2.078 1.302 2.286 0 0 .541 3.22 4.004 3.097 3.793-.158 3.702-5.678 3.702-5.678a16.485 16.485 0 00-.273-3.481l-1.485-.652-4.114-.181-.999.688-1.522 2.098.617 1.832z"
                  fill="#FFA88D"
                />
                <path
                  d="M10.997 13.505s-.858-2.496.617-3.501c1.307-1.148.101-1.531 1.115-2.15 1.013-.62 1.203-.433 1.686-.711.831-.252 1.743-.344 2.57-.027.52.2.919.58 1.312.961.196.19-.437.537-.207.687.242.158 1.173.811 1.173.811s.726.429.516 1.274c-.172 1.066.113 1.645-.154 2.941a.637.637 0 00-.216.11s-.195-2.972-.704-3.426c-.266-.237-.98.488-2.342.504-1.245.014-3.107-.87-3.435-.51-.688.751-.582 3.865-.582 3.865-.108.327-.464.301-.464.301s.037-.476-.239-.83a1.462 1.462 0 00-.646-.3z"
                  fill="#0A3855"
                />
                <path
                  d="M19.28 15.071s2.087 8.64-2.53 8.64c-3.798.134-4.868-3.694-4.868-9.08.137-.204.448-1.365.458-.927.055 1.17.967 2.355 1.606 2.532.639.177.304-1.103 2.565-1.357 2.263-.255 1.923 1.174 2.347 1.067.422-.107.422-.875.422-.875zm-1.652 1.008a.262.262 0 00.072-.07c.09-.156-.15-.204-.247-.2-.129.004-.25.042-.376.062-.195.03-.392.024-.584-.013-.22-.042-.488-.076-.657.105a.195.195 0 00-.055.14c.012.165.26.213.387.242.44.1.954.038 1.346-.19.038-.024.077-.048.114-.076z"
                  fill="#0A3855"
                />
                <path
                  d="M19.262 9.574L17.9 2.646c-.445-.95-4.94-.803-5.92.236l-.367 7.122c2.292-.788 6.352-.871 7.65-.43z"
                  fill="#F8A605"
                />
                <path
                  d="M15.922 6.13c-.852 1.654-2.304 2.751-4.282 3.358-.517 0-.352-3.04.152-2.92 1.498.018 2.848-.484 4.131-1.216v.777h-.001z"
                  fill="#EF8200"
                />
                <path
                  d="M15.922 6.13c.797 1.575 1.747 2.471 3.236 2.912C19.529 9 18.922 6 18.574 6.16c-.652.144-1.886-.263-2.652-.808v.777z"
                  fill="#EF8200"
                />
                <path
                  d="M15.475 5.937c-.009-.38.184-.693.43-.699.245-.005.452.298.46.678.01.38-.183.693-.429.699-.246.005-.452-.298-.46-.678z"
                  fill="#fff"
                />
                <path
                  d="M15.635 5.933c-.006-.243.117-.442.275-.446.158-.003.29.19.296.433.005.243-.118.442-.276.446-.157.004-.29-.19-.295-.433z"
                  fill="#F8A605"
                />
                <path
                  d="M23.435 31.482l2.465 8.965c1.298 3.963 6.12 3.088 5.256-1.505 0 0-1.967-8.965-2.14-9.104-.173-.14-5.58 1.644-5.58 1.644z"
                  fill="#FFA88D"
                />
                <path
                  d="M22.333 21.055s6.067.185 8.525 12.86c-2.231.685-4.39.907-6.553.67-4.449-7.213-6.466-12.692-1.972-13.53z"
                  fill="#FA4E4E"
                />
                <path
                  d="M24.447 34.6l.127-4.225.738 4.303-.865-.078z"
                  fill="#1B91CC"
                />
                <path
                  d="M16.282 31.167H26.85c-1.192-.052-2.704 5.367-2.258 10.303.112 1.231.361 2.256.683 3.083h5.568c-1.69.008-1.438 1.062-2.297 2.269-.427.599-1.293.5-2.113-.354-.067.116-.14.233-.225.354-.644.906-2.297.217-3.27-2.27h-.324c-1.69.01-1.438 1.063-2.297 2.27-.427.599-1.294.5-2.114-.354-.066.116-.14.233-.224.354-.86 1.207-3.507-.415-3.954-5.352-.447-4.937 1.066-10.357 2.257-10.303z"
                  fill="#F4EFE9"
                />
                <path
                  d="M18.62 31.166H26.5c1.468.158 1.777 1.767 1.777 1.767H17.48s-.006-1.714-1.197-1.767h2.337z"
                  fill="#fff"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_1_77">
                <rect width={32} height={32} rx={16} fill="#fff" />
              </clipPath>
              <clipPath id="clip1_1_77">
                <path
                  fill="#fff"
                  transform="matrix(-1 0 0 1 44 2)"
                  d="M0 0H46V48H0z"
                />
              </clipPath>
            </defs>
          </svg>
        );
      case "attention":
        return (
          <svg
            width={style?.width || "25"}
            height={style?.height || "24"}
            viewBox="0 0 25 24"
            style={style}
          >
            <g clip-path="url(#clip0_18_1035)">
              <path
                d="M12.0888 0L14.4334 0.230922L16.6879 0.914814L18.7656 2.02539L20.5868 3.51998L22.0814 5.34115L23.1919 7.41889L23.8758 9.67338L24.1068 12.018L23.8758 14.3626L23.1919 16.6171L22.0814 18.6948L20.5868 20.516L18.7656 22.0106L16.6879 23.1211L14.4334 23.805L12.0888 24.0359L9.74418 23.805L7.4897 23.1211L5.41195 22.0106L3.59078 20.516L2.09619 18.6948L0.985615 16.6171L0.301723 14.3626L0.0708008 12.018L0.301723 9.67338L0.985615 7.41889L2.09619 5.34115L3.59078 3.51998L5.41195 2.02539L7.4897 0.914814L9.74418 0.230922L12.0888 0Z"
                fill="#F8A506"
              />
              <path
                d="M12.0887 15.0225V6.00897M12.0887 18.0392V18.6278"
                stroke="white"
                strokeWidth="2.40359"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_18_1035">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.0708008)"
                />
              </clipPath>
            </defs>
          </svg>
        );
      case "photo":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            fill="none"
            style={style}
            width={style?.width || "32"}
            height={style?.height || "32"}
          >
            <path
              d="M26.2128 7.22592H22.6741L20.9341 4.58851C20.8408 4.44728 20.7146 4.33148 20.5665 4.25135C20.4184 4.17123 20.2531 4.12925 20.0851 4.12915H11.9149C11.7469 4.12925 11.5816 4.17123 11.4335 4.25135C11.2854 4.33148 11.1592 4.44728 11.066 4.58851L9.3247 7.22592H5.78725C4.97467 7.22592 4.19537 7.55219 3.62079 8.13295C3.04621 8.71371 2.72342 9.50138 2.72342 10.3227V24.7743C2.72342 25.5956 3.04621 26.3833 3.62079 26.9641C4.19537 27.5448 4.97467 27.8711 5.78725 27.8711H26.2128C27.0254 27.8711 27.8047 27.5448 28.3792 26.9641C28.9538 26.3833 29.2766 25.5956 29.2766 24.7743V10.3227C29.2766 9.50138 28.9538 8.71371 28.3792 8.13295C27.8047 7.55219 27.0254 7.22592 26.2128 7.22592ZM20.5958 17.0324C20.5958 17.9511 20.3262 18.8492 19.8212 19.6131C19.3162 20.377 18.5985 20.9724 17.7587 21.3239C16.919 21.6755 15.9949 21.7675 15.1034 21.5883C14.2119 21.409 13.3931 20.9666 12.7503 20.317C12.1076 19.6674 11.6699 18.8397 11.4926 17.9386C11.3152 17.0375 11.4063 16.1035 11.7541 15.2547C12.1019 14.406 12.691 13.6805 13.4468 13.1701C14.2025 12.6596 15.0911 12.3872 16 12.3872C17.2189 12.3872 18.3878 12.8766 19.2497 13.7478C20.1116 14.6189 20.5958 15.8004 20.5958 17.0324Z"
              fill="#999999"
            />
          </svg>
        );

      default:
        return null;
    }
  }
);

export default Icon;
