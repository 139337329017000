import styled from "styled-components";
import { Colors } from "../../const/colors";

const DealStyled = styled.div`
  background: ${Colors.White};
  height: 100vh;
  width: 100vw;
  overflow: auto;
`;

export { DealStyled };
