import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent, {
  ButtonBackgroundType,
  ButtonColorType,
} from "../../../components/ButtonComponent/ButtonComponent";
import FooterTabs from "../../../components/FooterTabs/FooterTabs";
import Icon from "../../../components/Icon/Icon";
import Loader from "../../../components/Loader/Loader";
import { useTelegram } from "../../../hooks/useTelegram";
import serviceApi from "../../../services/api";
import { UserType } from "../../../types";
import { routes } from "../../../types/enum";
import InputProfileComponent from "./components/InputProfileComponent/InputProfileComponent";
import {
  Avatar,
  ButtonWrapper,
  Content,
  Divider,
  Header,
  Name,
  ProfileDetailsStyled,
  UserData,
  UserForm,
} from "./ProfileDetails.styled";

export default function ProfileDetails() {
  const [loading, setLoading] = useState(false);
  const { data, isLoading } = useQuery<UserType>({
    queryKey: ["user"],
    queryFn: () => serviceApi.getUser(),
  });
  const [user, setUser] = useState<{ name: string; secondName: string }>({
    name: "",
    secondName: "",
  });
  const navigate = useNavigate();
  const { tg } = useTelegram();

  useEffect(() => {
    tg.BackButton.onClick(() => {
      navigate(routes.SETTINGS);
    });
    tg.BackButton.show();
  });

  useEffect(() => {
    if (data?.name && data?.secondName) {
      setUser({
        name: data?.name,
        secondName: data?.secondName,
      });
    }
  }, [data]);

  if (isLoading) {
    return (
      <Loader
        style={{
          height: "100vh",
          background: "linear-gradient(168.1deg, #F7EED6 -1.02%, #E3D2F1 100%)",
        }}
      />
    );
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      await serviceApi.editUser(JSON.stringify(user));
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }
  return (
    <ProfileDetailsStyled>
      <Content>
        <Header>
          <UserData>
            <Name>{user.name}</Name>
            <Name>{user.secondName}</Name>
            {/* <Id>{tg.initDataUnsafe.user?.id}</Id>
            <Id>{data?.id}</Id> */}
          </UserData>

          <Avatar>
            <Icon name="photo" />
          </Avatar>
        </Header>
        <UserForm>
          <InputProfileComponent
            placeholder="Your name"
            label="Name"
            required={!!!user.name}
            value={user.name}
            onChange={(val) => setUser({ ...user, name: val })}
          />

          <Divider />

          <InputProfileComponent
            placeholder="Your last name"
            label="Surname"
            value={user.secondName}
            required={!!!user.secondName}
            onChange={(val) => setUser({ ...user, secondName: val })}
          />
        </UserForm>

        <ButtonWrapper>
          <ButtonComponent
            backgroundType={ButtonBackgroundType.DarkBlue}
            colorType={ButtonColorType.White}
            disabled={(data?.name === user.name && data.secondName === user.secondName) || loading || !!!user.name || !!!user.secondName}
            onClick={handleSubmit}
          >
            Save
          </ButtonComponent>
        </ButtonWrapper>
      </Content>

      <FooterTabs activeTab="settings" />
    </ProfileDetailsStyled>
  );
}
