import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fontSizes } from "../../../../const/fonts";

const StepsLayoutStyled = styled.div`
  background: ${Colors.White};
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 65px;
  padding: 20px 20px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: linear-gradient(180deg, #ffffff 0%, #f3f0e8 100%);
`;

const CustomAgreement = styled.p`
  font-size: ${fontSizes.l};
  line-height: 1.2rem;
  color: ${Colors.DarkGrey};
`;

export { CustomAgreement, HeaderWrapper, StepsLayoutStyled };
