import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FooterTabs from "../../components/FooterTabs/FooterTabs";
import Loader from "../../components/Loader/Loader";
import PromoCard from "../../components/PromoCard/PromoCard";
import { useTelegram } from "../../hooks/useTelegram";
import serviceApi from "../../services/api";
import { routes } from "../../types/enum";
import { HomeStyled, Main } from "./Home.styled";
import ContractsList from "./components/ContractsList/ContractsList";
import CreateContractButton from "./components/CreateContractButton/CreateContractButton";

export default function Home() {
  const navigate = useNavigate();
  const refMain = useRef<HTMLDivElement>(null);
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ["deals"],
    queryFn: ({ pageParam = 1 }) => serviceApi.fetchDeals(pageParam),
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length === 10 ? pages.length + 1 : undefined;
    },
  });
  const { tg } = useTelegram();
  const { data: profile, error: profileError } = useQuery({
    queryKey: ["profile"],
    queryFn: serviceApi.getUser,
  });

  useEffect(() => {
    tg.BackButton.hide();
  });

  const loadMoreDeals = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY + window.innerHeight;
      const threshold = document.documentElement.scrollHeight - 800;

      if (scrollTop >= threshold) {
        loadMoreDeals();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loadMoreDeals]);
  return (
    <HomeStyled ref={refMain}>
      <Main>
        <PromoCard
          button="Try AI analytics"
          label="Solomon’s Contract Analysis: Legal AI for Everyone"
          onPress={() => navigate(`${routes.CONTRACT_CREATION}?type=solomon`)}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <ContractsList contracts={data?.pages.flat()} profile={profile} />
        )}
      </Main>
      <CreateContractButton onClick={() => navigate(routes.CONTRACT_CREATION)} />
      <FooterTabs activeTab="contracts" />
    </HomeStyled>
  );
}
