import styled from "styled-components";
import { Colors } from "../../../../const/colors";
import { fonts, fontSizes, lineHeights } from "../../../../const/fonts";

const DealPreviewTabStyled = styled.div`
  background: ${Colors.White};
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

const DealHeader = styled.div`
  background: ${Colors.Beige};
  padding: 20px 20px 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.h2`
  font-size: ${fontSizes.h2};
  line-height: ${lineHeights.h2};
  color: ${Colors.Dark};
  font-family: ${fonts.secondary};
  font-weight: 600;
`;

const People = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.EGreen};
  color: ${Colors.White};
`;

const OwnerStatus = styled.div`
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  background: ${Colors.White};
  color: ${Colors.DarkBlue};
  padding: 2px 8px 4px;
  border-radius: 17px;
  font-weight: 600;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const PeopleElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ElementData = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Name = styled.p`
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  color: ${Colors.Dark};
`;

const DealContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  padding: 16px 20px 32px;
`;

const DocumentPreview = styled.div`
  width: 100%;
  overflow: overlay;
  flex: 1 1 44vh;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  padding-top: 15px;

  button {
    font-size: ${fontSizes.xxl_medium};
    line-height: ${lineHeights.xxl_medium};
    font-weight: 500;
  }
`;

export {
  Avatar,
  ButtonsWrapper,
  DealContent,
  DealHeader,
  DealPreviewTabStyled,
  DocumentPreview,
  ElementData,
  Name,
  OwnerStatus,
  People,
  PeopleElement,
  Title,
};
