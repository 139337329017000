import { useNavigate } from "react-router-dom";
import BackButton from "../../../../components/BackButton/BackButton";
import Badges from "../../../../components/Badges/Badges";
import ButtonComponent, {
  ButtonBackgroundType,
  ButtonColorType,
} from "../../../../components/ButtonComponent/ButtonComponent";
import { routes } from "../../../../types/enum";
import { ContractSteps } from "../../ContractCreation";
import {
  BeginTabStyled,
  ButtonsWrapper,
  ContentWrapper,
  Description,
  ImageWrapper,
  Img,
  SolomonWrapper,
  TextContent,
  Title,
} from "./BeginTab.styled";

type BeginTabProps = {
  setActiveTab: (tab: ContractSteps) => void;
  type: string | null;
};
export default function BeginTab({ setActiveTab, type }: BeginTabProps) {
  const navigate = useNavigate();

  function renderType() {
    switch (type) {
      case "solomon":
        return (
          <>
            <SolomonWrapper>
              <Badges name="opiniongpt" style={{ width: "180px", height: "180px" }} />
            </SolomonWrapper>
            <TextContent>
              <Title>Solomon AI</Title>
              <Description>
                Cutting-edge sophisticated Opinion Generation functionality that revolutionizes the
                way businesses and individuals approach legal|documents. <br /> This feature is
                designed to empower users with the ability to comprehensively understand, evaluate,
                and form opinions on various agreements, contracts, and legal texts, enabling more
                informed decision-making and strategic planning.
              </Description>
            </TextContent>
          </>
        );
      case "custom":
      default:
        return (
          <>
            <ImageWrapper>
              <Img src="./assets/custom_agreement.png" />
            </ImageWrapper>
            <TextContent>
              <Title>Custom Agreement</Title>
              <Description>
                Upload any contract you desire, sign it using the e-gree system, and ensure the
                validity of the contract.
                {type}
              </Description>
            </TextContent>
          </>
        );
    }
  }

  return (
    <BeginTabStyled>
      <ContentWrapper>{renderType()}</ContentWrapper>

      <ButtonsWrapper>
        <BackButton onClick={() => navigate(routes.HOME)} />
        <ButtonComponent
          backgroundType={ButtonBackgroundType.EGreen}
          colorType={ButtonColorType.White}
          onClick={() => setActiveTab("upload")}
        >
          Begin
        </ButtonComponent>
      </ButtonsWrapper>
    </BeginTabStyled>
  );
}
