import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterTabs from "../../components/FooterTabs/FooterTabs";
import { useTelegram } from "../../hooks/useTelegram";
import { routes } from "../../types/enum";
import { Menu, MenuItem, SettingsStyled, Title, Wrapper, Link } from "./Settings.styled";

export default function Settings() {
  const navigate = useNavigate();
  const { tg } = useTelegram();

  useEffect(() => {
    tg.BackButton.hide();
  });
  return (
    <SettingsStyled>
      <Wrapper>
        <Title>Settings & more</Title>

        <Menu>
          <MenuItem onClick={() => navigate(routes.PROFILE)}>Profile details</MenuItem>
          <MenuItem><Link href="https://dev.e-gree.app/terms" target="_blank">Terms of use</Link></MenuItem>
          <MenuItem><Link href="https://dev.e-gree.app/privacy" target="_blank">Privacy</Link></MenuItem>
        </Menu>
      </Wrapper>

      <FooterTabs activeTab="settings" />
    </SettingsStyled>
  );
}
