import styled from "styled-components";
import { Colors } from "../../../../const/colors";

const ContractsListStyled = styled.div`
  width: 100%;
  flex: 1;
`;

const NoContractsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const NoContractsText = styled.p`
  line-height: 1.2rem;
  color: ${Colors.Dark};
  font-weight: 500;
`;

const ContractsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

export { ContractsListStyled, ContractsWrapper, NoContractsText, NoContractsWrapper };
