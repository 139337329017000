import styled from "styled-components";
import { Colors } from "../../const/colors";
import { fonts, fontSizes, lineHeights } from "../../const/fonts";

const SettingsStyled = styled.div`
  background: linear-gradient(168.1deg, #f7eed6 -1.02%, #e3d2f1 100%);
  height: 100vh;
  width: 100vw;
`;

const Wrapper = styled.div`
  padding: 32px 20px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 90px;
`;

const Title = styled.h1`
  font-family: ${fonts.secondary};
  font-size: ${fontSizes.h1};
  line-height: ${lineHeights.h1};
  color: ${Colors.Dark};
  font-weight: 600;
`;

const Menu = styled.ul`
  width: 100%;
`;

const MenuItem = styled.li`
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  color: ${Colors.Dark};
  font-weight: 700;
  padding: 16px 0;
  border-bottom: 1px solid rgba(153, 153, 153, 0.4);
  width: 100%;
  list-style: none;
  cursor: pointer;
`;

const Link = styled.a`
  text-decoration: none;
  font-size: ${fontSizes.l};
  line-height: ${lineHeights.l};
  color: ${Colors.Dark};
  font-weight: 700;
`;

export { Menu, MenuItem, SettingsStyled, Title, Wrapper, Link };
